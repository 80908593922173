<div class="wrap disp-flex row mobile-col-reverse">
  <div class="left disp-inline-flex col view-min-height">
    <div>
      <div class="component-wrap">
        <div class="buttons disp-flex">
          <div class="button-wrap">
            <button (click)="state = MEDIA_TYPE.MODEL" [ngClass]="{ active: state === MEDIA_TYPE.MODEL }" type="button"
              mat-raised-button class="btn btn-g large-desktop small-text">
              3D
            </button>
          </div>
          <div class="button-wrap">
            <button (click)="state = MEDIA_TYPE.IMAGE" [ngClass]="{ active: state === MEDIA_TYPE.IMAGE }" type="button"
              mat-raised-button class="btn btn-g large-desktop small-text">
              Images
            </button>
          </div>
          <div class="button-wrap">
            <button (click)="state = MEDIA_TYPE.VIDEO" [ngClass]="{ active: state === MEDIA_TYPE.VIDEO }" type="button"
              mat-raised-button class="btn btn-g large-desktop small-text">
              Videos
            </button>
          </div>
        </div>
      </div>
    </div>
    @if (state === MEDIA_TYPE.MODEL) {
    <app-creations-filter class="space-bottom-xl elem"></app-creations-filter>
    <div class="hidden-desktop txt-xxs bold desc-text capitalize-fl">
      select 3D model
    </div>
    <app-creations class="elem no-space-top no-space-right-desktop" (onSelect)="onSelect($event)"
      [type]="CREATIONS_LIST_TYPE.DESKTOP_INFO_HORIZON_THUMBS_MOBILE" [counter]="generateService.counter"
      [actions]="true"></app-creations>
    } @else if (state === MEDIA_TYPE.IMAGE) {
    <div class="capitalize-fl txt-sm space-bottom-lg elem hidden-mobile">
      select and generate 3D models from previously generated images
    </div>
    <app-generated-images class="elem" (onSelect)="onImageSelect($event)"
      [type]="IMAGE_LIST_TYPE.HORIZONTAL_MOBILE"></app-generated-images>
    } @else if (state === MEDIA_TYPE.VIDEO) {
    <div class="capitalize-fl txt-sm space-bottom-lg elem hidden-mobile">
      view one of your videos
    </div>
    <app-generated-videos class="elem" (onSelect)="onVideoSelect($event)"
      [type]="IMAGE_LIST_TYPE.HORIZONTAL_MOBILE"></app-generated-videos>
    }
  </div>
  @if (!hasContent()) {
  <div class="flex-1 disp-flex align-items-center">
    <div class="disp-flex col align-items-center center-me">
      <div class="space-bottom-md">
        <img
          [src]="'/assets/images/' + (state === MEDIA_TYPE.MODEL ? 'cube.svg' : state === MEDIA_TYPE.IMAGE ? 'picture.svg' : 'video-creation.svg')"
          alt="asset" class="input-type">
      </div>
      <div class="txt-md gray-50-color">
        @if (state === MEDIA_TYPE.MODEL) {
        Select a model
        }
        @else if (state === MEDIA_TYPE.IMAGE) {
        Select an image
        }
        @else {
        Select an video
        }
      </div>
    </div>
  </div>
  }
  @else {
  <div class="flex-def disp-flex creation-wrap" [ngClass]="{'has-content': hasContent() }">
    <button type="button" mat-raised-button mat-dialog-close class="btn large btn-g close" (click)="close()">
      close
    </button>
    <div class="view-min-height flex-1 right" [ngClass]="{ empty: !hasContent() }">
      @if (state === MEDIA_TYPE.MODEL) {
      <div class="desc col hidden-desktop align-items-center justify-content-center w-100">
        <img src="/assets/images/cube.svg" alt="cube" />
        <div class="text-center">Select 3D model</div>
      </div>
      } @else {
      <div class="desc col hidden-desktop align-items-center justify-content-center w-100">
        <img src="/assets/images/image.svg" alt="cube" class="sharpen1" />
        <div class="text-center">Select image & generate 3D model</div>
      </div>
      }
      @if (state === MEDIA_TYPE.MODEL && generateService.creation) {
      <app-view-creation class="disp-block h-100" [creation]="generateService.creation"></app-view-creation>
      } @else if(state === MEDIA_TYPE.IMAGE && generateService.reconstructedImage) {
      <app-view-generated-image [item]="generateService.reconstructedImage" [is-private]="isPrivate()" [job-item]="generateService.creation"></app-view-generated-image>
      } @else if(state === MEDIA_TYPE.VIDEO && generateService.videoCreation) {
      <app-view-generated-video [item]="generateService.videoCreation"></app-view-generated-video>
      }
    </div>
  </div>
  <div class="similar-wrap" [ngClass]="{ open: showSimilar }">
    @if (generateService.hasPreview()) {
    <button type="button" mat-raised-button class="btn btn-a inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()">
      <img src="/assets/images/marketplace-1.svg" alt="marketplace" />
    </button>
    <button type="button" mat-raised-button class="btn btn-c close-inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()">
      <img src="/assets/images/close.svg" alt="close" class="va-bottom" />
    </button>
    <app-similar class="disp-block" [items]="generateService.similarItems" (onSelect)="setSimilar($event)"
      (onClose)="showSimilar = false"></app-similar>
    }
  </div>
  }
</div>