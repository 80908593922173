<div class="wrap component-wrap">
    <div class="generate-by-type space-top">
        @if (generate2dHelper.type === GENERATE_TYPE.CREATE_VIDEO && isSU) {
        <div class="buttons disp-flex space-bottom-md">
            <div class="button-wrap">
                <button (click)="videoMethod = null" [ngClass]="{ active: videoMethod === null }" type="button"
                    mat-raised-button class="btn btn-g large small-text">
                    Text Prompt
                </button>
            </div>
            <div class="button-wrap">
                <button (click)="videoMethod = VIDEO_METHOD.SVC"
                    [ngClass]="{ active: videoMethod === VIDEO_METHOD.SVC }" type="button" mat-raised-button
                    class="btn btn-g large small-text sp">
                    Camera Trajectory
                </button>
            </div>
        </div>
        }
        @if (generate2dHelper.type === GENERATE_TYPE.CREATE_VIDEO && videoMethod === VIDEO_METHOD.SVC) {
        <div class="disp-flex space-bottom-sm">
            <span class="txt-xxs bold capitalize-fl">camera trajectory</span>
        </div>
        <mat-form-field class="w-100 has-padd">
            <mat-select [(ngModel)]="trajectoryType">
                @for (t of trajectoryTypes; track $index) {
                <mat-option [value]="t.value">{{t.value}}</mat-option>
                }
            </mat-select>
        </mat-form-field>
        }
        @else {
        <div class="space-bottom-xs">
            <div class="disp-flex">
                <span class="txt-xxs bold capitalize-fl">insert prompt</span>
                <a href="javascript:void(0)" class="align-end-flex link txt-xxs bold" (click)="examplePrompt()">example
                    prompt</a>
            </div>
            <mat-form-field class="w-100 ta-wrap">
                <textarea rows="6" [(ngModel)]="generateService.text"
                    placeholder="dragon wings and unicorn head hybrid creature, highly detailed..." matInput></textarea>
            </mat-form-field>
        </div>
        }
        <div class="pos-rel">
            <div class="space-bottom-sm disp-flex row">
                <div class="txt-xxs bold capitalize-fl">
                    upload image{{generateService.allowMultipleImages ? 's' :''}}
                </div>
                <div class="align-end-flex txt-xxs">
                    @if (!imageToUpload.length) {
                    <a class="link underline txt-xxs bold" href="javascript:void(0)" (click)="setExampleImage()">
                        Example image
                    </a>
                    }
                    @else if (generateService.allowMultipleImages) {
                    <a class="link underline txt-xxs bold" href="javascript:void(0)" (click)="addMoreImages()">
                        Add images
                    </a>
                    }
                </div>
            </div>
            <div class="file-card-wrap disp-flex space-bottom-lg"
                [ngClass]="{'no-files': !imageToUpload.length, 'single-file': imageToUpload.length === 1, 'multiple-files': imageToUpload.length > 1}">
                <input placeholder="drop image here" type="file" class="poiner"
                    accept="image/png, image/jpeg, image/webp" [multiple]="generateService.allowMultipleImages"
                    (change)="onFilesChange($event)" [id]="uploadImagesID" />
                <div class="pseudo-img pos-rel">
                    @if (!imageToUpload.length) {
                    <img src="/assets/images/picture.svg" alt="upload file"
                        class="vertical-align center-me disp-block" />
                    }
                    @else {
                    <div class="pseudo-img-slider" [ngStyle]="{'width': getSliderWidth()}">
                        @for (img of imageToUpload; track $index) {
                        <div class="pseudo-img-inner">
                            <button type="button" class="delete-image pointer pos-abs" (click)="removeImage($index)">
                                <mat-icon>clear</mat-icon>
                            </button>
                            <img [src]="img" placeholder="input image" alt="input image"
                                class="vertical-align center-me disp-block" />
                        </div>
                        }
                    </div>
                    }
                </div>
                <div class="desc disp-flex col align-content-center">
                    <div class="txt-sm bold">
                        upload image{{generateService.allowMultipleImages ? 's' : ''}}
                    </div>
                    <div class="txt-xxs gray-50-color">
                        jpeg, png, webp.
                    </div>
                </div>
            </div>
            @if (this.auth.user) {
            <div class="space-bottom-md">
                <div class="disp-flex space-bottom-sm">
                    <span class="txt-xxs bold capitalize-fl">License</span>
                    <img src="/assets/images/question-mark.svg" class="align-end-flex link txt-xxs bold tt-private"
                        alt="info" [matTooltip]="ttPrivate">
                </div>
                <div class="buttons disp-flex">
                    <div class="button-wrap">
                        <button (click)="isPrivate = false" [ngClass]="{ active2: !isPrivate }" type="button"
                            mat-raised-button class="btn btn-ghost small-text">
                            Public
                        </button>
                    </div>
                    <div class="button-wrap">
                        <button (click)="isPrivate = true" [ngClass]="{ active2: isPrivate }" type="button"
                            mat-raised-button class="btn btn-ghost small-text">
                            Private
                        </button>
                    </div>
                </div>
            </div>
            }
            <button type="button" mat-raised-button class="btn btn-a large w-100" [disabled]="isDisable()"
                (click)="generate()">
                {{generate2dHelper.type === GENERATE_TYPE.CREATE_VIDEO ? 'Create video' : 'Edit image'}}
            </button>
            @if (showLogin) {
            <div class="login-request">
                @if (oneTapFailure) {
                Please <a class="txt-xxs link" [routerLink]="['/login']">login</a> first.
                }
                @else {
                <div class="warn-color txt-xxs">Please login first.</div>
                }
            </div>
            }
            @if (actionDetails) {
            <app-cost-est [cost]="actionDetails.credits" [est]="actionDetails.estimated_duration"
                class="disp-block"></app-cost-est>
            }
            @if (uploadingImage) {
            <mat-progress-bar class="img-loading pos-abs" mode="indeterminate" color="accent"></mat-progress-bar>
            }
        </div>
    </div>
</div>