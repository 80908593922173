<button class="advanced-btn" mat-mini-fab [disabled]="disabled" (click)="toggleMenu(!isOpen)">
    <img src="/assets/images/settings.svg" alt="settings">
</button>
<div class="menu" [ngClass]="{'open': isOpen}" style="display: none;">
    <div class="header pos-rel">
        advanced options
        <button type="button" mat-mini-fab class="btn btn-ghost close pos-abs" (click)="toggleMenu(false)">
            <img class="btn-icon" src="/assets/images/close.svg" alt="close" />
        </button>
    </div>
    <mat-nav-list>
        @if (action === RECONSTRUCTION_ACTION.RECONSTRUCTION) {
        <div mat-list-item>
            <div class="space-bottom-md">
                <span matListItemTitle class="space-bottom-md">
                    <mat-slide-toggle labelPosition="before" [(ngModel)]="randomizedSeed" (change)="onRandomChange()">
                        <span class="txt-sm bold">Random seed</span>
                    </mat-slide-toggle>
                </span>
                <span matLine class="disable-color txt-xxs hidden-desktop">{{ttSeed}}</span>
            </div>

            <div>
                <span matListItemTitle>
                    <div class="space-bottom-sm pos-rel">
                        <span class="txt-xxs bold" [ngClass]="{'disable-color': randomizedSeed}">Fixed seed
                            number</span>
                        <img class="tooltip" src="/assets/images/question-mark.svg" alt="info" [matTooltip]="ttSeed">
                    </div>
                    <mat-form-field class="w-100 no-subscript">
                        <input placeholder="Seed number" [disabled]="randomizedSeed" matInput type="number"
                            [(ngModel)]="options.seed" (input)="onChange()" />
                    </mat-form-field>
                </span>
            </div>
        </div>
        <div mat-list-item class="space-bottom-md">
            <div class="space-bottom-md">
                <span matListItemTitle>
                    <mat-slide-toggle labelPosition="before" [(ngModel)]="options.low_poly"
                        (change)="onLowPolyChange()">
                        <span class="txt-sm bold">Low poly</span>
                    </mat-slide-toggle>
                </span>
                <span matLine class="disable-color txt-xxs hidden-desktop">{{ttLow}}</span>
            </div>
            <div>
                <span matListItemTitle>
                    <div class="space-bottom-sm pos-rel">
                        <span class="txt-xxs bold" [ngClass]="{'disable-color': options.low_poly}">Face limit (max
                            100,000)</span>
                        <img class="tooltip" src="/assets/images/question-mark.svg" alt="info" [matTooltip]="ttLow">
                    </div>
                    <mat-form-field class="w-100 no-subscript">
                        <!-- <span matTextPrefix>,000</span> -->
                        <input placeholder="50,000 to 100,000" [disabled]="options.low_poly" matInput type="number"
                            [(ngModel)]="options.poly_count" min="5000" max="100000" (input)="onChange()" />
                    </mat-form-field>
                </span>
            </div>
        </div>
        } @else if (action === RECONSTRUCTION_ACTION.REFINE || action === RECONSTRUCTION_ACTION.REFINE_PBR || action ===
        RECONSTRUCTION_ACTION.RE_TEXTURE || action === RECONSTRUCTION_ACTION.RE_TEXTURE_PBR) {
        <div mat-list-item>

            <div class="space-bottom-xs">
                <span matListItemTitle>
                    <mat-slide-toggle labelPosition="before" [(ngModel)]="options.pbr" (change)="onPBRChange()"
                        class="pos-rel">
                        <span class="txt-sm bold coin-wrap">
                            PBR (+ {{pbrAdd}}<img src="/assets/images/coin.svg" alt="coin" class="va-middle coin">)
                            <img class="tooltip large hidden-mobile" src="/assets/images/question-mark.svg" alt="info"
                                [matTooltip]="ttPBR">
                        </span>
                    </mat-slide-toggle>
                </span>
                <span matLine class="disable-color txt-xxs hidden-desktop">{{ttPBR}}</span>
            </div>
        </div>

        <div mat-list-item>

            <div class="space-bottom-xs">
                <span matListItemTitle>
                    <mat-slide-toggle labelPosition="before" [(ngModel)]="options.smoothness" (change)="onChange()"
                        class="pos-rel">
                        <span class="txt-sm bold coin-wrap">
                            Smoothness
                            <img class="tooltip large hidden-mobile" src="/assets/images/question-mark.svg" alt="info"
                                [matTooltip]="ttSmoothness">
                        </span>
                    </mat-slide-toggle>
                </span>
                <span matLine class="disable-color txt-xxs hidden-desktop">{{ttSmoothness}}</span>
            </div>
        </div>

        <!-- <div mat-list-item>
            <div class="space-bottom-xs">
                <span matListItemTitle>
                    <mat-slide-toggle labelPosition="before" [(ngModel)]="options.quad_remesh" (change)="onChange()" class="pos-rel">
                        <span class="txt-sm bold coin-wrap">
                            Quad remesh
                            <img class="tooltip large hidden-mobile" src="/assets/images/question-mark.svg" alt="info"
                                [matTooltip]="ttQR">
                        </span>
                    </mat-slide-toggle>

                </span>
                <span matLine class="disable-color txt-xxs hidden-desktop">{{ttQR}}</span>
            </div>
        </div> -->
        }

        <!-- <div mat-list-item>
            <span matListItemTitle>
                <mat-slide-toggle labelPosition="before" [(ngModel)]="options.private" (change)="onLowPolyChange()">
                    <span class="default-color">Private</span>
                </mat-slide-toggle>
            </span>
            <span matLine>Will not be showen at <b>{{PlatformName}}</b> community</span>
        </div> -->
    </mat-nav-list>
</div>

<!-- <button mat-button [matMenuTriggerFor]="menu" [disabled]="disabled">
    <img src="/assets/images/settings.svg" alt="settings">
</button>
<mat-menu #menu="matMenu" [hasBackdrop]="false">
    <div class="section">
        <mat-slide-toggle labelPosition="before" [(ngModel)]="randomizedSeed" (change)="onRandomChange()"
            (click)="menuClick($event)">
            <span class="default-color">Randomized seed</span>
        </mat-slide-toggle>
    </div>
    <div class="section">
        <mat-form-field class="w-100">
            <input placeholder="Ex. 58" [disabled]="!randomizedSeed" matInput type="number"
                [(ngModel)]="options.seed" />
        </mat-form-field>
    </div>
    <div class="section">
        <mat-slide-toggle labelPosition="before" [(ngModel)]="options.low_poly"
            (change)="onLowPolyChange()">
            <span class="default-color">Low poly</span>
        </mat-slide-toggle>
    </div>
    <div class="section">
        <mat-form-field class="w-100">
            <input placeholder="50,000 to 100,000" [disabled]="options.low_poly"
                matInput type="number" [(ngModel)]="options.poly_count" min="5000"
                max="100000" />
        </mat-form-field>
    </div>
</mat-menu> -->





<!-- <mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title> Advanced Options </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <p><mat-slide-toggle labelPosition="before" [(ngModel)]="randomizedSeed" (change)="onRandomChange()">
                    <span class="default-color">Randomized seed</span>
                </mat-slide-toggle>
            </p>
        </div>
        <div>
            <div class="txt-xxs bold space-bottom-xs">
                Seed number
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input placeholder="Ex. 58" [disabled]="!randomizedSeed" matInput type="number"
                        [(ngModel)]="options.seed" />
                </mat-form-field>
            </div>
        </div>


        <div>
            <p>
                <mat-slide-toggle labelPosition="before" [(ngModel)]="options.low_poly"
                    (change)="onLowPolyChange()" [disabled]="true">
                    <span class="default-color">Low poly</span>
                </mat-slide-toggle>
            </p>
        </div>
        <div>
            <div class="txt-xxs bold space-bottom-xs">
                Poly count
            </div>
            <div>
                <mat-form-field class="w-100">
                    <input placeholder="50,000 to 100,000" [disabled]="options.low_poly"
                        matInput type="number" [(ngModel)]="options.poly_count" min="5000"
                        max="100000" />
                </mat-form-field>
            </div>
        </div>

    </mat-expansion-panel>
</mat-accordion> -->