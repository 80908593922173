@if (gService.fetchingSimilar) {
<div class="pos-rel">
  <mat-progress-bar class="pos-abs" mode="indeterminate" color="accent"></mat-progress-bar>
</div>
}
<div class="wrap">
  <div class="hidden-desktop">
    <div class="disp-flex space-bottom-md padd-sides">
      <button
        mat-raised-button
        aria-label="mobile menu"
        class="align-end-flex btn btn-c btn-img no-marg-img"
        (click)="close()"
      >
        <img [src]="'/assets/images/close.svg'" alt="menu" class="va-middle" />
      </button>
    </div>
  </div>
  <h3 class="no-marg ttl-sm">3D Marketplace</h3>
  <div class="desc txt-xs">Expertly Designed Ready-to-Use Collection</div>
  @if (items?.length) {
  <div class="list">
    @for (item of items; track $index) {
    <app-similar-item
      class="pointer"
      [item]="item"
      (click)="select(item)"
    ></app-similar-item>
    }
  </div>
  } @else { @if (gService.fetchingSimilar) {
  <div class="txt-xxs space-top">fetching collection . . .</div>
  } @else {
  <div class="txt-xxs space-top">No similar models were found</div>
  } }
</div>
