<div class="wrap disp-flex row mobile-col-reverse">
    <div class="left disp-inline-flex col view-min-height">
        <app-upscale (on-private)="setPrivate($event)"></app-upscale>
        @if (isLogin) {
        <app-generated-images class="elem" (onSelect)="onImageSelect($event)" [type]="IMAGE_LIST_TYPE.HORIZONTAL_MOBILE"
            [actions]="actions"></app-generated-images>
        }
    </div>
    @if (!hasContent()) {
    <div class="flex-1 disp-flex align-items-center">
        <div class="disp-flex col align-items-center center-me">
            <div class="desc text-center space-bottom-md no-content">
                <img src="/assets/images/image-upscale.svg" alt="picture" class="space-bottom-md" />
                <h1 class="ttl-xxs space-bottom-md">
                    Image Resolution Enhancer
                </h1>
                <div class="txt-xxs space-bottom-md lh text-start">
                    Upload your photo to boost resolution and enhance image quality and clarity, transforming it into a
                    high-definition masterpiece!
                </div>
            </div>
            <!-- <app-upscale></app-upscale> -->
        </div>
    </div>
    }
    @else {
    <div class="flex-def disp-flex creation-wrap pos-rel" [ngClass]="{'has-content': hasContent() }">
        <div class="view-min-height flex-1 right" [ngClass]="{empty: !generateService.reconstructedImage}">
            @if (!hasContent()) {
            <div class="desc col hidden-desktop align-items-center justify-content-center w-100">
                <img src="/assets/images/image.svg" alt="cube" class="sharpen1" />
                <div class="text-center">Select image & generate 3D model</div>
            </div>
            }
            @if(hasContent()) {
            <button type="button" mat-raised-button mat-dialog-close class="btn large btn-g pos-abs close"
                (click)="generateService.reconstructedImage = null">
                close
            </button>
            <app-view-generated-image [item]="generateService.reconstructedImage" [is-private]="isPrivate"></app-view-generated-image>
            }
        </div>
    </div>
    <div class="similar-wrap" [ngClass]="{ open: showSimilar }">
        @if (this.generateService.reconstructedImage) {
        <button type="button" mat-raised-button class="btn btn-a inventory btn-img no-marg-img hidden-desktop"
            (click)="toggleSimilar()">
            <img src="/assets/images/marketplace-1.svg" alt="marketplace" />
        </button>
        <button type="button" mat-raised-button class="btn btn-c close-inventory btn-img no-marg-img hidden-desktop"
            (click)="toggleSimilar()">
            <img src="/assets/images/close.svg" alt="close" class="va-bottom" />
        </button>
        <app-similar class="disp-block" [items]="generateService.similarItems" (onSelect)="setSimilar($event)"
            (onClose)="showSimilar = false"></app-similar>
        }
    </div>
    }
</div>