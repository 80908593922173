import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  IAdvancedOptions,
  IReconstruct,
  IReconstructJobUI,
  IReconstructionAction,
  RECONSTRUCTION_ACTION,
  RECONSTRUCT_JOB_STATUS,
} from '../generate';
import { ViewerComponent } from '../../shared/viewer/viewer.component';
import { GenerateService } from '../generate.service';
import { UtilsService } from '../../shared/utils.service';
import {
  ScreenNotificationType,
  PlaygroundNotificationType,
  IPlaygroundNotification,
} from '../../shared/enums';
import { BroadcasterService } from 'ng-broadcaster';
import { RestService } from '../../communication/rest.service';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { ViewerService } from '../../shared/viewer.service';
import { Subscription } from 'rxjs';
import { MatButtonModule } from '@angular/material/button';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { PixelsService } from '../../shared/pixels.service';
import { PreCreationPreviewComponent } from '../pre-creation-preview/pre-creation-preview.component';
import { FormsModule } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PricingDialogComponent } from '../../pricing/pricing-dialog/pricing-dialog.component';
import { AdvancedOptionsComponent } from '../advanced-options/advanced-options.component';

@Component({
  selector: 'app-view-creation',
  imports: [
    ViewerComponent,
    MatButtonModule,
    CostEstComponent,
    PreCreationPreviewComponent,
    FormsModule,
    AdvancedOptionsComponent
  ],
  templateUrl: './view-creation.component.html',
  styleUrl: './view-creation.component.scss'
})
export class ViewCreationComponent implements OnChanges, OnDestroy {
  @Input() creation: IReconstructJobUI;
  @Input() counter: number;
  @Output() onPreview: EventEmitter<string>;
  private _subs: Array<Subscription>;
  public action: IReconstructionAction;
  public viewerService: ViewerService;
  public advancedOptions: IAdvancedOptions;
  constructor(
    private generateService: GenerateService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService,
    private rest: RestService,
    private resumable: ResumableUploadService,
    private pixels: PixelsService,
    private auth: AuthService,
    private dialog: MatDialog
  ) {
    this.viewerService = new ViewerService(this.utils);
    this.onPreview = new EventEmitter<string>();
    this._subs = [];

    this._subs.push(
      this.viewerService.onPreview.subscribe(this.setPreview.bind(this))
    );
    this._subs.push(
      this.viewerService.onRefine.subscribe(this.refine.bind(this))
    );
    this._subs.push(
      this.viewerService.onDownload.subscribe(this.download.bind(this))
    );
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe((data: any) => {
        this.onAnnouncement(data);
      })
    );
    this.init();
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.afterChange();
    if (changes['creation'] && changes['creation'].previousValue && changes['creation'].previousValue.id !== changes['creation'].currentValue.id) {
      this.advancedOptions = {};
      this.setAction();
    }
  }

  async afterChange() {
    if (this.creation.status === RECONSTRUCT_JOB_STATUS.Completed) {
      await this.setViewerURL();
      this.setCanRefine();
      this.setCreatePreview();
      this.setCanDownload();
    }
  }

  async onAnnouncement(data: IPlaygroundNotification) {
    if (
      data.notifications_types?.find(
        (t) =>
          t.id === PlaygroundNotificationType.JOB_STATUS_CHANGE ||
          t.id === PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED
      )
    )
      if (
        data.job_id === this.creation?.id &&
        this.creation.status !== data.status
      ) {
        this.creation = await this.generateService.updateJob(this.creation);
        this.afterChange();
      }
  }

  private async init() {
    this.advancedOptions = {};
    this.setAction();
  }

  async setAction() {
    this.action = (
      await this.generateService.getAction(this.advancedOptions.pbr ? RECONSTRUCTION_ACTION.REFINE_PBR : RECONSTRUCTION_ACTION.REFINE)
    );
  }

  private async setViewerURL() {
    this.viewerService.setViewerUrl(this.creation.viewer_url);
    this.viewerService.glbUrl = this.creation.glb_url;
  }

  private setCanRefine() {
    this.viewerService.canRefine =
      this.creation.action_id === RECONSTRUCTION_ACTION.RECONSTRUCTION;
  }

  private setCreatePreview() {
    const next = !!!this.utils.getPreview(this.creation);
    if (next && this.viewerService.generatePreview) {
      this.viewerService.generatePreview = false;
      setTimeout(() => {
        this.viewerService.generatePreview = true;
      });
    } else this.viewerService.generatePreview = next;
  }

  private setCanDownload() {
    this.viewerService.canDownlopad = !!this.creation.glb_url;
  }

  download() {
    this.utils.multipleDownloads([this.creation.glb_url], 'model.glb');
  }

  async refine() {
    const action_id = this.advancedOptions.pbr ? RECONSTRUCTION_ACTION.REFINE_PBR : RECONSTRUCTION_ACTION.REFINE;
    if (!this.generateService.checkCreditsAndPrompt((await this.generateService.getAction(action_id)).credits, true))
      return;
    this.pixels.sendPixel({
      event: 'click',
      button_name: 'refine_model',
      pbr: this.advancedOptions.pbr,
      low_poly: this.advancedOptions.low_poly
    });
    if (this.auth.subscription) {
      const payload = {
        action_id,
        source_job_id: this.creation.id,
        text: this.utils.getPreview(this.creation)
      } as IReconstruct;
      if (this.utils.isURL(this.creation.preview)) {
        payload.images = this.creation.reconstruction_jobs_inputs.map(r => r.image_url);
        if (!payload.images.length)
          payload.images = [this.creation.preview];
      }
      else payload.text = this.creation.preview;
      if (
        this.creation.reconstruction_jobs_inputs &&
        this.creation.reconstruction_jobs_inputs[0]?.text_input
      )
        payload.text = this.creation.reconstruction_jobs_inputs[0].text_input;
      try {
        const job = await this.generateService.imageTo3D(payload, this.advancedOptions);
        this.utils.notifyUser({
          type: ScreenNotificationType.Neutral,
          text: 'refining . . .',
        });
        this.broadcaster.broadcast('onGenerating', job);
      } catch (e: any) {
        this.utils.notifyUser({
          type: ScreenNotificationType.Error,
          text: e?._body || e?.error || 'Request failed, please try again later'
        });
      }
    }
    else {
      this.dialog.open(PricingDialogComponent, {
        data: action_id
      });
    }
  }

  async setPreview(src: string) {
    if (this.creation.id) {
      const mime = this.resumable.base64MimeType(src);
      const preview = await this.resumable.base64ToURL(
        src,
        'preview.png',
        mime
      );
      // const payload = {
      //   id: this.creation.id,
      //   preview
      // };
      this.creation.preview = preview;
      this.onPreview.next(preview);
      const creation = this.utils.deepCopyByValue(this.creation);
      creation.notifications_types = [
        {
          description: 'UI update',
          id: PlaygroundNotificationType.JOB_STATUS_CHANGE,
        },
      ];
      creation.job_id = creation.id;
      this.broadcaster.broadcast('onJobUpdate', creation);
      await this.utils.observableToPromise(
        this.rest.reconstructJobs('PUT', this.creation)
      );
    }
  }

  showPre() {
    return this.creation.status !== RECONSTRUCT_JOB_STATUS.Completed;
  }

  onOptionsChange(options: IAdvancedOptions) {
    this.advancedOptions = options;
    this.setAction();
  }

  ngOnDestroy() {
    this._subs.forEach((s) => s.unsubscribe());
    this.viewerService.destroy();
  }
}
