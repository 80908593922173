<div class="h-100 pos-rel wrap">
  @if (item) {
  <div class="disp-block creation vertical-align-desktiop-only">
    <img [src]="item.url" alt="reconstructed image" (load)="onImgLoad()">
    @if (allImageOptions) {
    <div class="disp-flex row options-wrap">
      <div class="disp-flex col hidden-mobile">
        <div class="txt-md bold space-bottom-sm">Select an action</div>
        <div class="txt-xxs bold disable-color">generate video, 3d model, edit image or upscale</div>
      </div>

      <div class="disp-flex col align-end-flex">
        <app-options-button [current-action]="allImageOptions[0].action" [options]="allImageOptions"
          (onClick)="onActionClick($event)" (onChange)="onActionChange($event)" css-class="btn btn-a"
          class="space-bottom-md"></app-options-button>
        <!-- @if (selectedAction) {
        <app-cost-est [cost]="selectedAction.credits" [est]="selectedAction.estimated_duration"
          class="disp-block"></app-cost-est>
        } -->
      </div>
    </div>
    }
  </div>
  }
  @else if (job) {
  @if (percentage) {
  <div class="disp-flex pc-center-center center-me col h-100 text-center">
    <h3>Generating images... {{ percentage }}%</h3>
    <div class="desc txt-xxs">
      This may take a some time, you can wait here or generate another image.
      <br />
      We’ll notify you when its ready.
    </div>
  </div>
  }
  }
  @if (loadingImg) {
  <mat-progress-bar mode="indeterminate" class="pos-abs" color="accent">
  </mat-progress-bar>
  }