import { Component, OnDestroy, OnInit } from '@angular/core';
import { BallsComponent } from '../shared/balls/balls.component';
import { MatButtonModule } from '@angular/material/button';
import { FooterComponent } from '../footer/footer.component';
import { RightsComponent } from '../rights/rights.component';
import { MatDividerModule } from '@angular/material/divider';
import { RouterLink } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { MetaOptions } from '../shared/seo/seo';
import { PlatformName } from '../shared/constants';
import { environment } from '../../environments/environment';
import { SeoService } from '../shared/seo/seo.service';
import { BroadcasterService } from 'ng-broadcaster';
import { Subscription } from 'rxjs';
import { PixelsService } from '../shared/pixels.service';

@Component({
    selector: 'app-site',
    imports: [BallsComponent, MatButtonModule, FooterComponent, RightsComponent, MatDividerModule, RouterLink],
    templateUrl: './site.component.html',
    styleUrl: './site.component.scss'
})
export class SiteComponent implements OnDestroy, OnInit {
  private _subs: Array<Subscription>;
  constructor(
    private auth: AuthService,
    private seo: SeoService,
    private broadcaster: BroadcasterService,
    private pixels: PixelsService
  ) {
    document.body.classList.add('gradient');
    this._subs = [];
    this._subs.push(this.broadcaster.on('onHeaderAction').subscribe((this.onHeaderAction as any).bind(this)));
  }

  async ngOnInit() {
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | Home Page`;
    mOptions.keywords = `${PlatformName}, Free, 3D Models, AI`;
    mOptions.description = `${mOptions.description} On this page you can read about the platform.`;
    mOptions.canonical = `${environment.domain}`;
    this.seo.setMetaDate(mOptions);
  }

  getVidElement(evt: any): HTMLVideoElement {
    return evt.srcElement || evt.target;
  }

  loadedmetadata(evt: any) {
    const e = this.getVidElement(evt);
    if (e)
      e.muted = true;
  }

  canplay(evt: any) {
    const e = this.getVidElement(evt);
    if (e)
      e.play().catch(console.error);
  }

  onHeaderAction(route: string) {
    let name = '';
    switch (route) {
      case 'start_for_free':
      case 'login':
      case 'pricing': {
        name = route;
        break;
      }
    }
    if (name)
      this.sendClickPixel(name, 'upper_pane');
  }

  sendClickPixel(sub_click_type: string, button_position: string) {
    this.pixels.sendPixel({
      event: 'click',
      sub_click_type,
      button_text: sub_click_type,
      button_position
    });
  }

  ngOnDestroy() {
    document.body.classList.remove('gradient');
  }
}
