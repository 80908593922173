<a [routerLink]="[link]" mat-raised-button class="btn btn-d" routerLinkActive="active" (click)="onAction(link)">
    <div class="disp-flex row inner-link">
        <img [src]="img" [alt]="alt" class="va-text-top" />
        <span class="disp-flex col">
            <span class="ttl-xxs">{{text}}</span>
            <span class="txt-xxs">
                {{subtext}}
            </span>
        </span>
    </div>
</a>