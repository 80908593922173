import { Component, OnDestroy, OnInit } from '@angular/core';
import { PromptComponent } from '../prompt/prompt.component';
import { GenerateResultsComponent } from '../generate-results/generate-results.component';
import { SimilarComponent } from '../similar/similar.component';
import { CreationsComponent } from '../creations/creations.component';
import { GENERATE_TYPE, IReconstructJobUI, ISimilarItem } from '../generate';
import { GenerateService } from '../generate.service';
import { ViewCreationComponent } from '../view-creation/view-creation.component';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SimilarPreviewComponent } from '../similar-preview/similar-preview.component';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { IPlaygroundNotification } from '../../shared/enums';
import { MatDividerModule } from '@angular/material/divider';
import { noPaddDialog, PlatformName } from '../../shared/constants';
import { MatButtonModule } from '@angular/material/button';
import { SeoService } from '../../shared/seo/seo.service';
import { MetaOptions } from '../../shared/seo/seo';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-generate',
  imports: [
    PromptComponent,
    GenerateResultsComponent,
    SimilarComponent,
    CreationsComponent,
    ViewCreationComponent,
    RouterLink,
    CommonModule,
    MatDividerModule,
    MatButtonModule,
  ],
  templateUrl: './generate.component.html',
  styleUrl: './generate.component.scss'
})
export class GenerateComponent implements OnDestroy, OnInit {
  public showSimilar: boolean;
  public isPrivate: boolean;
  private _subs: Array<Subscription>;
  constructor(
    public generateService: GenerateService,
    private dialog: MatDialog,
    private broadcaster: BroadcasterService,
    private seo: SeoService,
    public auth: AuthService
  ) {
    this.generateService.generatingImagesJob = null;
    this._subs = [];
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe((data: any) => {
        this.onAnnouncement(data);
      })
    );
    this._subs.push(
      this.broadcaster.on('onLatestUpdated').subscribe((data: any) => {
        if (
          // !generateService.creation &&
          !generateService.createdImages
        ) {
          generateService.creation = data;
        }
      })
    );
    switch (location.pathname.split('\/')[1]) {
      case 'free-online-ai-3d-image-to-model-generator': {
        this.generateService.state = GENERATE_TYPE.IMAGE_TO_3D;
        break;
      }
      case 'free-online-ai-text-to-3d-model-generator': {
        this.generateService.state = GENERATE_TYPE.TEXT_TO_3D;
        break;
      }
    }
  }

  async ngOnInit() {
    const mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | Images and 3D models Generative AI`;
    // mOptions.keywords = `${PlatformName}, Free, 3D Models, AI, picture, image, online, ${this.generateService.state === GENERATE_TYPE.IMAGE_TO_3D ? 'image to 3D' : 'text to 3D'}`;
    if (this.generateService.state === GENERATE_TYPE.IMAGE_TO_3D)
      mOptions.keywords = `AI 3D model generator, image to 3D converter, free online 3D model creator, AI 3D design tool, realistic 3D modeling, 3D image editor, free 3D modeling, image based 3D conversion, 3D model editing, 4K textures`;
    else
      mOptions.keywords = `AI text to 3D generator, text to 3D model, free online 3D model creator, AI 3D modeling, text-based 3D design, free 3D modeling, prompt-based 3D transformation, innovative 3D design tool, AI 3D editor, 4K textures`;
    mOptions.description = `${mOptions.description} On this page you can create 3D models out of ${this.generateService.state === GENERATE_TYPE.IMAGE_TO_3D ? 'images' : 'text'}. You can also create AI generated images from text. The usage is free of charge for a few creations each month.`;
    mOptions.canonical = `${environment.domain}${this.generateService.state === GENERATE_TYPE.IMAGE_TO_3D ? 'free-online-ai-3d-image-to-model-generator' : 'free-online-ai-text-to-3d-model-generator'}`;
    this.seo.setMetaDate(mOptions);
  }

  onAnnouncement(data: IPlaygroundNotification) {
    // if (
    //   this.generateService.latestUpdated &&
    //   data.job_id === this.generateService.latestUpdated.id
    // )
    //   this.generateService.counter++;
    this.generateService.counter++;
  }

  onSelect(item: IReconstructJobUI) {
    this.generateService.creation = item;
    this.generateService.counter++;
  }

  setSimilar(similar: ISimilarItem) {
    document.body.classList.add(noPaddDialog);
    this.dialog.open(SimilarPreviewComponent, {
      data: similar,
    });
    this.dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove(noPaddDialog);
    });
  }

  async setPreview(imageURL: string) {
    this.generateService.similarItems =
      await this.generateService.getSimilarProducts(imageURL);
  }

  // close() {
  //   this.generateService.creation = null;
  //   this.generateService.createdImages = null;
  // }

  toggleSimilar() {
    this.showSimilar = !this.showSimilar;
  }

  isEmpty() {
    return !this.generateService.creation && !this.generateService.createdImages;
  }

  setPrivate(value: boolean) {
    this.isPrivate = value;
  }

  ngOnDestroy() {
    this.generateService.counter = 0;
    this.generateService.creation = null;
    this.generateService.generatingImagesJob = null;
    this._subs.forEach((f) => f.unsubscribe());
  }
}
