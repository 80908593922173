@if (showPre()) {
<app-pre-creation-preview [item]="creation" [counter]="counter"></app-pre-creation-preview>
} @else {
<div class="h-100 pos-rel">
  @if (viewerService.element) {
  <app-viewer [viewerService]="viewerService" class="disp-block h-100" [controls]="true" [grid]="true"></app-viewer>
  } @if (this.viewerService.canRefine) {
  <div class="pos-abs refine-wrap text-center horizontal-align disp-flex col mobile-row-reverse">
    <div class="txt-xxs bold desc">want to view at higher quality?</div>
    @if (action) {
    <div class="disp-flex">
      <div class="advanced-button w-100">
        <button type="button" mat-raised-button class="btn btn-a w-100" (click)="refine()">
          <img class="btn-icon" src="/assets/images/refine.svg" alt="coin" />
          Refine <span class="hidden-mobile">model</span>
        </button>
        <app-advanced-options [action]="action.id" [options]="advancedOptions"
          (options-change)="onOptionsChange($event)"></app-advanced-options>
      </div>
    </div>
    <app-cost-est [cost]="action.credits" [est]="action.estimated_duration" [seperate-mobile]="true"
      class="disp-block"></app-cost-est>
    }
  </div>
  }
</div>
}