<div class="disp-flex space-bottom-sm">
    <span class="txt-xxs bold">Select image to upload</span>
    @if (imageToLoad) {
    <a class="txt-xxs bold link underline align-end-flex" href="javascript:void(0)"
        (click)="imageToLoad = null">Clear</a>
    }
</div>
<div class="pos-rel space-bottom-md file-wrap" [ngClass]="{ 'has-file': imageToLoad }">
    @if (!imageToLoad && loadingImageFiles) {
    <mat-progress-bar class="pos-abs" mode="indeterminate" color="accent"></mat-progress-bar>
    }
    <input placeholder="drop image here" type="file" class="poiner" accept="image/png, image/jpeg, image/webp"
        miltiple="false" (change)="onImageFilesChange($event)" />
    <div class="dummy disp-flex pos-abs col text-center">
        <img src="/assets/images/picture.svg" alt="image" class="icon" />
        <div>
            <div class="desc1 txt-xs capitalize-fl space-bottom-sm">
                upload image
            </div>
            <div class="desc2 txt-xxs">
                png, jpeg, webp
            </div>
        </div>
    </div>
    <img [src]="imageToLoad" alt="reference image" class="reference-image">
</div>
@if (usAction) {
<app-cost-est class="disp-block space-bottom-md" [cost]="usAction.credits"
    [est]="usAction.estimated_duration"></app-cost-est>
}
@if (this.auth.user) {
<div class="space-bottom-md">
    <div class="disp-flex space-bottom-sm">
        <span class="txt-xxs bold capitalize-fl">License</span>
        <img src="/assets/images/question-mark.svg" class="align-end-flex link txt-xxs bold tt-private" alt="info"
            [matTooltip]="ttPrivate">
    </div>
    <div class="buttons disp-flex">
        <div class="button-wrap">
            <button (click)="isPrivate = false" [ngClass]="{ active2: !isPrivate }" type="button" mat-raised-button
                class="btn btn-ghost small-text">
                Public
            </button>
        </div>
        <div class="button-wrap">
            <button (click)="isPrivate = true" [ngClass]="{ active2: isPrivate }" type="button" mat-raised-button
                class="btn btn-ghost small-text">
                Private
            </button>
        </div>
    </div>
</div>
}
<button class="btn btn-a space-bottom-md w-100" mat-raised-button
    [disabled]="!imageToLoad || imageDim > maxDim || loadingImageFiles" (click)="generate()">
    <img src="/assets/images/refine.svg" alt="refine" class="va-text-top" />
    Upscale image
</button>
@if (showLogin) {
@if (oneTapFailure) {
<a class="txt-xxs link" [routerLink]="['/login']">Please login first.</a>
}
@else {
<div class="warn-color txt-xxs">Please login first.</div>
}
}
@if (job) {
<div class="pos-rel">
    @if (percentage) {
    <mat-progress-bar class="pos-abs" mode="determinate" [value]="percentage" color="accent">
    </mat-progress-bar>
    }@else {
    <mat-progress-bar class="pos-abs" mode="indeterminate" color="accent">
    </mat-progress-bar>
    }
</div>


}
@if (imageToLoad && imageDim > maxDim && !loadingImageFiles) {
<div class="warn-color txt-xxs">Image maximum size ({{maxDim}} pixels) was exceeded.</div>
}