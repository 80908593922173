import {
  AfterViewInit,
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnDestroy,
  ViewChild,
  Input,
} from '@angular/core';
import { ViewerService } from '../../shared/viewer.service';
import { environment } from '../../../environments/environment';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ExportFileType, IExpotedModel } from 'hexa-viewer-communicator';
import { GenerateService } from '../generate.service';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { UtilsService } from '../../shared/utils.service';
import { CommonModule } from '@angular/common';
import { GENERATE_TYPE, IAdvancedOptions, IReconstructionAction, IReconstructTexture, RECONSTRUCTION_ACTION } from '../generate';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { themeColor } from '../../shared/constants';
import { EnumsService } from '../../shared/enums.service';
import { THREE_LATEST_VERSION } from 'asset-adjustments';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatDialogRef } from '@angular/material/dialog';
import { AdvancedOptionsComponent } from '../advanced-options/advanced-options.component';
import { AuthService } from '../../auth/auth.service';
import { PixelsService } from '../../shared/pixels.service';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-ai-texture',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    CommonModule,
    CostEstComponent,
    MatProgressBar,
    AdvancedOptionsComponent,
    RouterLink
  ],
  templateUrl: './ai-texture.component.html',
  styleUrl: './ai-texture.component.scss'
})
export class AiTextureComponent implements AfterViewInit, OnDestroy {
  public GENERATE_TYPE = GENERATE_TYPE;
  public viewerServer: string;
  public text: string;
  private _examplePromptIndex: number;
  private exporderModel: IExpotedModel;
  public viewerService: ViewerService;
  public action: IReconstructionAction;
  public loadingImageFiles: boolean;
  public imageToLoad: string;
  public themeColor = themeColor;
  public inputType: GENERATE_TYPE;
  public advancedOptions: IAdvancedOptions;
  public showLogin: boolean;
  public oneTapFailure: boolean;
  THREE_LATEST_VERSION = THREE_LATEST_VERSION
  @ViewChild('hexaViewer') hexaViewer: ElementRef;
  @Input('glb-url') glbURL: string;
  @Input('dialog-ref') dialogRef: MatDialogRef<any>;
  constructor(
    private generateService: GenerateService,
    private resumableUploadService: ResumableUploadService,
    private utils: UtilsService,
    private enums: EnumsService,
    private auth: AuthService,
    private pixels: PixelsService,
  ) {
    this._examplePromptIndex = 0;
    this.viewerService = new ViewerService(this.utils);
    this.viewerServer = environment.viewerServer;
    this.inputType = GENERATE_TYPE.TEXT_TO_3D;
    this.advancedOptions = {};
  }

  ngAfterViewInit() {
    this.init();
  }

  async setAction() {
    this.action = (
      await this.generateService.getAction(this.advancedOptions.pbr ? RECONSTRUCTION_ACTION.RE_TEXTURE_PBR : RECONSTRUCTION_ACTION.RE_TEXTURE)
    );
  }

  async init() {
    const text = document.getElementById('re-texture-textarea');
    if (text) {
      setTimeout(() => {
        text.focus();
      }, 500);
    }
    await this.setAction();
    await this.viewerService.injectScript();
    this.hexaViewer.nativeElement.setAttribute('server', this.viewerServer);
    this.hexaViewer.nativeElement.setAttribute('tv', THREE_LATEST_VERSION);
    this.hexaViewer.nativeElement.setAttribute('theme-color', this.themeColor);
    this.viewerService.defineVC(this.hexaViewer.nativeElement);
    await this.viewerService.vc.onModelLoaded();
    this.handleModel();
  }

  isDisabled() {
    const inputValid = this.inputType === GENERATE_TYPE.TEXT_TO_3D ? this.text : this.imageToLoad;
    return (!inputValid || !this.exporderModel) && (!inputValid || !this.glbURL);
  }

  async handleModel() {
    this.exporderModel = await this.viewerService.vc.expotModel({
      compressPNG: false,
      downloadFile: false,
      type: ExportFileType.GLB,
    });
    // For the next time that the user might drop a file:
    this.exporderModel = null;
    await this.viewerService.vc.onModelLoaded();
    this.handleModel();
  }

  async texture() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'texture_3d_model',
    });
    if (!await this.auth.isloggedIn()) {
      this.showLogin = true;
      try {
        await this.auth.oneTapAuth();
        this.showLogin = false;
        this.texture();
      } catch (e) {
        this.oneTapFailure = true;
      }
    }
    else {
      if (!this.generateService.checkCreditsAndPrompt(this.action.credits, true))
        return;
      let glbUrl = this.glbURL;
      if (!glbUrl) {
        const blob = new Blob([this.exporderModel.buffer], {
          type: 'application/octet-stream',
        }) as any;
        blob.lastModifiedDate = new Date();
        blob.name = 'scene.glb';
        glbUrl = await this.resumableUploadService.file(blob);
      }
      const payload = { modelURL: glbUrl } as IReconstructTexture;
      if (this.imageToLoad)
        payload.images = [this.imageToLoad];
      else
        payload.text = this.text;
      this.generateService.generateTexture(payload, this.advancedOptions);
    }
  }

  examplePrompt() {
    const prompts = this.enums.getTextureExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.text = p;
  }
  async onImageFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.loadingImageFiles = true;
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp')
        continue;
      this.imageToLoad = await this.resumableUploadService.file(file);
      break;
    }
    event.srcElement.value = '';
    this.loadingImageFiles = false;
  }

  close() {
    this.dialogRef.close();
  }

  onOptionsChange(options: IAdvancedOptions) {
    this.advancedOptions = options;
    this.setAction();
  }

  ngOnDestroy() {
    this.viewerService.destroy();
  }
}
