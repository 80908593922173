<div class="wrap disp-flex mobile-col-reverse">
  <div class="prompt-wrap view-min-height">
    <div class="disp-flex prompt-inner">
      <app-prompt (on-private)="setPrivate($event)"></app-prompt>
      <div class="divider-wrap disp-flex hidden-mobile">
        <mat-divider class="w-100"></mat-divider>
      </div>
      <div class="component-wrap disp-flex align-items-center hidden-mobile">
        <h3 class="txt-sm bold capitalize no-marg flex-1">my creations</h3>
        <a [routerLink]="['/creations']" class="txt-xxs link bold">
          view all
        </a>
      </div>
      <app-creations class="component-wrap" [current]="generateService.creation" [counter]="generateService.counter"
        (onSelect)="onSelect($event)" [actions]="true"></app-creations>
      <!-- @if (generateService.latestUpdated) {
      <div class="hidden-desktop">
        <h3 class="txt-sm bold capitalize no-marg flex-1 hidden-mobile">
          current job
        </h3>
        <app-creation-card
          (onSelect)="onSelect($event)"
          [item]="generateService.latestUpdated"
          [counter]="generateService.counter"
        ></app-creation-card>
      </div>
      } -->
    </div>
  </div>
  @if (isEmpty()) {
  <div class="creation-wrap view-min-height">
    <div class="h-100 disp-flex align-items-center">
      <div class="disp-flex col align-items-center center-me">
        <div class="txt-md gray-50-color desc-wrap text-center">
          @if (generateService.state === generateService.GENERATE_TYPE.IMAGE_TO_3D) {
          <img src="/assets/images/picture.svg" alt="3D" class="space-bottom-md desc-img" />
          <h1 class="ttl-xxs space-bottom-md">
            Convert images into 3D with AI
          </h1>
          <div class="txt-xxs space-bottom-md lh text-start">
            Convert any image into a detailed 3D model with our free online AI tool. Upload your image and let our
            advanced technology generate realistic, high-resolution 3D models for design, gaming, and virtual
            experiences.
          </div>
          }
          @else {
          <img src="/assets/images/text.svg" alt="3D" class="space-bottom-md desc-img" />
          <h1 class="ttl-xxs space-bottom-md">
            Convert text into 3D with AI
          </h1>
          <div class="txt-xxs space-bottom-md lh text-start">
            Create detailed and realistic 3D models simply by providing a text prompt. Our free online AI tool
            transforms your words into stunning 3D models perfect for design, gaming, and virtual experiences.
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  }
  @else {
  <div class="creation-wrap view-min-height" [ngClass]="{
      empty: isEmpty()
    }">
    @if(generateService.creation) {
    <app-view-creation class="disp-block h-100" [creation]="generateService.creation"
      [counter]="generateService.counter" (onPreview)="setPreview($event)"></app-view-creation>
    } @else if (generateService.createdImages) {
    <div class="back-header hidden-desktop disp-flex">
      <a href="javascript:void(0)" (click)="generateService.createdImages = null">
        <img src="/assets/images/back.svg" alt="back" />
      </a>
      <div class="ttl-xs flex-def text-center">Preview images</div>
    </div>
    <app-generate-results class="disp-block view-min-height" [waiting-mode]="generateService.generatingImages"
      [job]="generateService.generatingImagesJob" [images]="generateService.createdImages" [is-private]="isPrivate"
      [allow-3d]="true"></app-generate-results>
    }
  </div>
  <div class="similar-wrap" [ngClass]="{ open: showSimilar }">
    @if (generateService.hasPreview()) {
    <button type="button" mat-raised-button class="btn btn-a inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()">
      <img src="/assets/images/marketplace-1.svg" alt="marketplace" />
    </button>
    <button type="button" mat-raised-button class="btn btn-c close-inventory btn-img no-marg-img hidden-desktop"
      (click)="toggleSimilar()">
      <img src="/assets/images/close.svg" alt="close" class="va-bottom" />
    </button>
    <app-similar class="disp-block" [items]="generateService.similarItems" (onSelect)="setSimilar($event)"
      (onClose)="showSimilar = false"></app-similar>
    }
  </div>
  }
</div>