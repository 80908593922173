import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  IAdvancedOptions,
  IReconstruct,
  IReconstructImage,
  IReconstructJobUI,
  RECONSTRUCTION_ACTION,
} from '../generate';
import { GenerateService } from '../generate.service';
import { MatButtonModule } from '@angular/material/button';
import { PixelsService } from '../../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { IAssetOptions, ScreenNotificationType } from '../../shared/enums';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ProgressHelper } from '../../shared/progress-helper';
import { MAX_UPSCALE_DIM } from '../../shared/constants';
import { OptionsButtonComponent } from '../../shared/options-button/options-button.component';
import { EnumsService } from '../../shared/enums.service';

@Component({
  selector: 'app-view-generated-image',
  imports: [MatButtonModule, MatProgressBarModule, OptionsButtonComponent],
  templateUrl: './view-generated-image.component.html',
  styleUrl: './view-generated-image.component.scss'
})
export class ViewGeneratedImageComponent extends ProgressHelper implements OnChanges {
  @Input() item: IReconstructImage;
  @Input('job-item') jobItem: IReconstructJobUI;
  @Input('is-private') isPrivate: boolean;
  public loadingImg: boolean;
  public imageDim: number;
  public maxDim: number;
  public advancedOptions: IAdvancedOptions;
  public allImageOptions: Array<IAssetOptions>;
  constructor(
    private generateService: GenerateService,
    private pixels: PixelsService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private router: Router,
    private auth: AuthService,
    private dialog: MatDialog,
    private injector: Injector,
    private enums: EnumsService
  ) {
    super(injector);
    this.advancedOptions = {};
    this.maxDim = MAX_UPSCALE_DIM;
    this.setDefaultDim();
    this.loadingImg = true;
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.allImageOptions = null;
    if (changes['item']) {
      if (this.item) {
        this.loadingImg = true;
        this.imageDim = MAX_UPSCALE_DIM + 1;
        this.setDefaultDim();
        const d = await this.utils.getImageDim(this.item.url);
        this.imageDim = Math.max(d.x, d.y);
        this.imageDim = Math.max(this.imageDim, d.z);
        this.loadingImg = false;
      }
      else
        this.job = null;
    }
    if (changes['jobItem'])
      this.job = this.jobItem;
    if (this.job)
      this.loadingImg = false;
    if (this.item) {
      this.allImageOptions = this.enums.getImageActions(this.item.job_id, this.item.id);
      // this.setAction(this.allImageOptions[0].action);
    }
  }

  private setDefaultDim() {
    this.imageDim = MAX_UPSCALE_DIM + 1;
  }

  async generate() {
    if (!this.generateService.checkCreditsAndPrompt((await this.generateService.getAction(RECONSTRUCTION_ACTION.RECONSTRUCTION)).credits, true))
      return;
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_from_preview',
    });
    const payload = {
      action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
      images: [this.item.url]
    } as IReconstruct;
    if (this.jobItem) {
      payload.public = this.jobItem.public;
      if (this.jobItem.reconstruction_jobs_inputs.length)
        payload.text = this.jobItem.reconstruction_jobs_inputs[0].text_input;
    }
    else if (this.item.prompt) payload.text = this.item.prompt;
    const res = await this.generateService.imageTo3D(payload, this.advancedOptions);
    this.utils.notifyUser({
      type: ScreenNotificationType.Neutral,
      text: 'generating . . .',
    });
    this.broadcaster.broadcast('onPublish', res);
    // this.router.navigateByUrl(`/creations/${res.id}`);
    this.utils.forceRedirectTo(`/creations/${res.id}`);
  }

  onImgLoad() {
    this.loadingImg = false;
    this.destroyProgress();
  }

  onOptionsChange(options: IAdvancedOptions) {
    this.advancedOptions = options;
  }

  override async onCompleted() {
    this.job = this.utils.deepCopyByValue((await this.generateService.getJobById(this.job.id)).data.reconstruction_jobs) as IReconstructJobUI;
    if (this.job.action_id === RECONSTRUCTION_ACTION.GENERATE_IMAGE) {
      if (this.job.reconstruction_images?.length)
        this.utils.forceRedirectTo(`/creations/${this.job.id}/${this.job.reconstruction_images[0].id}`);
      else
        this.utils.forceRedirectTo(`/creations/${this.job.id}`);
    }
    else if (this.job.action_id === RECONSTRUCTION_ACTION.GENERATE_VIDEO)
      this.utils.forceRedirectTo(`/creations/${this.job.id}`);
  }

  override onFailed() {
    this.utils.notifyUser({
      text: 'Image creation failed',
      type: ScreenNotificationType.Error
    });
    this.generateService.afterAction();
  }

  onActionClick(selection: IAssetOptions) {
    if (selection.key)
      this.router.navigateByUrl(selection.key);
  }

  async onActionChange(selection: IAssetOptions) {
    // this.setAction(selection.action);
  }
}
