import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IPlaygroundNotification, PlaygroundNotificationType } from '../enums';
import { PixelsService } from '../pixels.service';
import { UtilsService } from '../utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { NotificationsService } from '../notifications.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MomentPipe } from '../moment.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { RECONSTRUCT_JOB_STATUS, RECONSTRUCTION_ACTION } from '../../generate/generate';
import { SrcSuffixPipe } from '../src-suffix.pipe';


@Component({
  selector: 'app-notifications',
  imports: [CommonModule, MatIconModule, MatDividerModule, MomentPipe, MatMenuModule, SrcSuffixPipe],
  templateUrl: './notifications.component.html',
  styleUrl: './notifications.component.scss'
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public isSidebarOpen: boolean;
  @ViewChild('wrap') wrap: ElementRef;
  private subs: Array<Subscription>;
  private lastTS: number;
  constructor(
    public notificationsService: NotificationsService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private pixels: PixelsService
  ) {
    this.isSidebarOpen = this.notificationsService.getSidebarState();
    this.notificationsService.onSidebarToggle.subscribe((state) => {
      setTimeout(() => {
        this.isSidebarOpen = state;
      });
    });
    this.lastTS = new Date().getTime();
    this.subs = [];
  }

  ngOnInit() {
    this.subs.push(this.notificationsService.onNotification.subscribe(this.onAnnouncement.bind(this)));
    this.subs.push(this.broadcaster.on('popstate').subscribe((e: any) => {
      if (this.isSidebarOpen) {
        // e.preventDefault();
        history.pushState(null, null, window.location.pathname);
        history.pushState(null, null, window.location.pathname);
        this.notificationsService.toggleSidebar(false);
      }
    }));
    this.broadcaster.on('onclick').subscribe(
      (elm: any) => {
        if (this.isSidebarOpen) {
          if (!this.utils.closest(elm, 'app-notifications') && !this.utils.closest(elm, '.toggle-notifications'))
            this.toggleSidebar();
        }
      }
    );
  }

  onAnnouncement(n: IPlaygroundNotification) {
    switch (n.notification_type) {
      case PlaygroundNotificationType.ANNOUNCEMENT: {
        this.showCustomBroadcast(n);
        break;
      }
      // case PlaygroundNotificationType.PAYMENT_APPROVED: {
      //   this.utils.notifyUser({ text: 'Payment was approved', type: NotificationType.Success });
      //   break;
      // }
      // case PlaygroundNotificationType.PAYMENT_CANCELED: {
      //   this.utils.notifyUser({ text: 'Payment was denied', type: NotificationType.Error });
      //   break;
      // }
    }
  }

  getTitleByNotification(n: IPlaygroundNotification) {
    const actionId = this.notificationsService.getAction(n);
    switch (n.notification_type) {
      case PlaygroundNotificationType.ANNOUNCEMENT: {
        return 'announcement';
      }
      // case PlaygroundNotificationType.JOB_STATUS_CHANGE: {
      //   return 'creation status change';
      // }
      // case PlaygroundNotificationType.ON_RECONSTRUCTION_STATUS_CHANGE: {
      //   return 'on reconstruction status change';
      // }
      // case PlaygroundNotificationType.ON_RECONSTRUCTION_STATUS_CHANGE_2: {
      //   return 'on reconstruction status change 2';
      // }
      // case PlaygroundNotificationType.ROLES_REFRESHED: {
      //   return 'roles refrershes';
      // }
      case PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED: {
        switch (actionId) {
          case RECONSTRUCTION_ACTION.GENERATE_IMAGE: {
            return 'images are ready';
          }
          case RECONSTRUCTION_ACTION.UPSCALE_IMAGE: {
            return 'image is ready';
          }
          case RECONSTRUCTION_ACTION.GENERATE_VIDEO: {
            return 'video is ready';
          }
          default: {
            return '3D model is ready';
          }
        }
      }
      case PlaygroundNotificationType.JOB_STATUS_CHANGE: {
        switch (n.status) {
          case RECONSTRUCT_JOB_STATUS['In Progress']: {
            switch (actionId) {
              case RECONSTRUCTION_ACTION.GENERATE_IMAGE: {
                return 'images generation started';
              }
              case RECONSTRUCTION_ACTION.UPSCALE_IMAGE: {
                return 'image upscale started';
              }
              case RECONSTRUCTION_ACTION.GENERATE_VIDEO: {
                return 'video generation started';
              }
              case RECONSTRUCTION_ACTION.UPSCALE_VIDEO: {
                return 'video upscale started';
              }
              default: {
                return '3D generation started';
              }
            }
          }
          case RECONSTRUCT_JOB_STATUS.Completed: {
            switch (actionId) {
              case RECONSTRUCTION_ACTION.GENERATE_IMAGE: {
                return 'images generation completed';
              }
              case RECONSTRUCTION_ACTION.UPSCALE_IMAGE: {
                return 'image upscale completed';
              }
              case RECONSTRUCTION_ACTION.UPSCALE_VIDEO: {
                return 'video upscale completed';
              }
              case RECONSTRUCTION_ACTION.GENERATE_VIDEO: {
                return 'video generation completed';
              }
              default: {
                return '3D generation completed';
              }
            }
          }
          case RECONSTRUCT_JOB_STATUS.Failed: {
            return 'generation failed';
          }
          case RECONSTRUCT_JOB_STATUS.Queued: {
            return 'action queued';
          }
        }
        break;
      }
      // case PlaygroundNotificationType.VERSION_UPDATE: {
      //   return 'version update';
      // }
      default: {
        return PlaygroundNotificationType[n.notification_type];
      }
    }
  }

  toggleSidebar() {
    this.notificationsService.toggleSidebar(!this.notificationsService.getSidebarState());
  }

  // getIcon(n: CreatorsNotification) {
  //   switch (n.notification_type) {
  //     case CreatorsNotificationType.FEEDBACK_RECEIVED: {
  //       return 'assignment_late';
  //     }
  //     case CreatorsNotificationType.JOB_ASSIGNED: {
  //       return 'assignment';
  //     }
  //     case CreatorsNotificationType.CUSTOM_BROADCAST: {
  //       return 'announcement';
  //     }
  //     case CreatorsNotificationType.RELEVANT_PUBLIC_OFFER: {
  //       return 'local_offer';
  //     }
  //     default: {
  //       return 'announcement';
  //     }
  //   }
  // }

  markAsRead(n: IPlaygroundNotification) {
    n.read = true;
    this.notificationsService.markAsRead(n.id);
  }

  showCustomBroadcast(n: IPlaygroundNotification) {
    this.notificationsService.showCustomBroadcast(n);
  }

  action(n: IPlaygroundNotification) {
    switch (n.notification_type) {
      // case PlaygroundNotificationType.FEEDBACK_RECEIVED: {
      //   this.utils.forceRedirectTo('/jobs/feedbacks/' + n.reference_id + '/' + n.artist_user_id + '?ref=FEEDBACK_RECEIVED');
      //   this.markAsRead(n);
      //   this.pixels.sendPixel({
      //     event: 'notificationClickFeedbackReceived',
      //     reference_id: n.id
      //   });
      //   break;
      // }
      case PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED: {
        if (n.reference_id) {
          if (n.reconstruction_jobs?.length && (n.reconstruction_jobs[0].action_id === RECONSTRUCTION_ACTION.GENERATE_IMAGE || n.reconstruction_jobs[0].action_id === RECONSTRUCTION_ACTION.UPSCALE_IMAGE) && n.reconstruction_jobs[0].reconstruction_images?.length) {
            this.utils.forceRedirectTo(`/creations/${n.reference_id}/${n.reconstruction_jobs[0].reconstruction_images[0].id}`, {});
          }
          else
            this.utils.forceRedirectTo(`/creations/${n.reference_id}`, {});
        }

        this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'click',
          click_type: 'model_ready_notification',
          reference_id: n.reference_id,
          notification_id: n.id
        });
        break;
      }
      case PlaygroundNotificationType.ANNOUNCEMENT: {
        // this.markAsRead(n);
        this.pixels.sendPixel({
          event: 'click',
          click_type: 'announcement_notification',
          notification_id: n.id
        });
        this.showCustomBroadcast(n);
        break;
      }
      default: {
        this.markAsRead(n);
        break;
      }
    }
    this.toggleSidebar();
    this.broadcaster.broadcast('notificationAction', n);
  }

  onScroll(evt: any) {
    let maxPercentage = 0.6;
    let total = evt.target.scrollHeight;
    let current = evt.target.scrollTop;
    let position = current / total;
    if (position > maxPercentage) {
      if (this.utils.isAboveTS(new Date().getTime(), this.lastTS, 2000)) {
        this.notificationsService.fetchMore();
        this.lastTS = new Date().getTime();
      }
    }
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
  }
}
