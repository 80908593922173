import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IAdvancedOptions, IReconstructionAction, RECONSTRUCTION_ACTION, SUBSCRIPTION_REASON } from '../generate';
import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { GenerateService } from '../generate.service';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UtilsService } from '../../shared/utils.service';
import { MatDialog } from '@angular/material/dialog';
import { PricingDialogComponent } from '../../pricing/pricing-dialog/pricing-dialog.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { PlatformName } from '../../shared/constants';
import { CommonModule } from '@angular/common';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
  selector: 'app-advanced-options',
  imports: [MatBottomSheetModule, MatButtonModule, MatListModule, MatSlideToggleModule, FormsModule, MatInputModule, CommonModule, MatTooltipModule],
  templateUrl: './advanced-options.component.html',
  styleUrl: './advanced-options.component.scss'
})
export class AdvancedOptionsComponent implements OnInit {
  @Input('action') action: RECONSTRUCTION_ACTION;
  @Input('disabled') disabled: boolean;
  @Input('options') options: IAdvancedOptions;
  @Output('options-change') onOptionsChange: EventEmitter<IAdvancedOptions>;
  public randomizedSeed: boolean;
  public isOpen: boolean;
  public actionDetails: IReconstructionAction;
  public PlatformName = PlatformName;
  public RECONSTRUCTION_ACTION = RECONSTRUCTION_ACTION;
  public ttSeed: string;
  public ttLow: string;
  public ttPBR: string;
  public ttQR: string;
  public ttSmoothness: string;
  public pbrAdd: number;
  private _subs: Array<Subscription>;
  constructor(
    private generateService: GenerateService,
    // private _bottomSheet: MatBottomSheet
    private utils: UtilsService,
    private dialog: MatDialog,
    private broadcaster: BroadcasterService
  ) {
    this.onOptionsChange = new EventEmitter<IAdvancedOptions>();
    this.ttSeed = 'You can choose a randomized or fixed seed. A fixed seed ensures you get the same output every time.';
    this.ttLow = 'Affecting how light reflects off it to create realistic textures';
    this.ttPBR = 'Accurately simulating how light interacts with different materials, like metal, wood, or fabric';
    this.ttQR = 'Quad Remesh';
    this.ttSmoothness = 'Softer transitions between the faces, making the surface appear more continuous and less faceted.';
    this._subs = [];
    this._subs.push(this.broadcaster.on('onclick').subscribe((elm: any) => {
      if (this.isOpen) {
        if (!this.utils.closest(elm, 'app-advanced-options') && !this.utils.closest(elm, '.advanced-placeholder'))
          this.toggleMenu(false);
      }
    }))
  }

  ngOnInit() {
    this.syncActionDetails();
  }

  // menuClick(evt: any) {
  //   evt.preventDefault();
  //   evt.stopPropagation();
  // }

  // open() {
  //   this.optionsAction = this.action;
  //   this.options = this.advancedOptions || {};
  //   this._bottomSheet.open(AdvancedOptionsSheetComponent, {
  //     data: this.advancedOptions
  //   })
  //   // .afterDismissed().subscribe(() => {
  //   //   this.options
  //   // });
  // }

  async syncActionDetails() {
    this.actionDetails = await this.generateService.getAction(this.action);
    this.pbrAdd = (await this.generateService.getAction(RECONSTRUCTION_ACTION.REFINE_PBR)).credits - (await this.generateService.getAction(RECONSTRUCTION_ACTION.REFINE)).credits;
  }

  onRandomChange() {
    if (!this.randomizedSeed)
      this.options.seed = null;
    this.onChange();
  }

  onLowPolyChange() {
    if (this.options.low_poly)
      this.options.poly_count = null;
    this.onChange();
  }

  async onPBRChange() {
    if (this.options.pbr) {
      const actionDetails = await this.generateService.getAction(RECONSTRUCTION_ACTION.REFINE_PBR);
      if (!this.generateService.validateSubscription(actionDetails)) {
        this.options.pbr = false;
        this.showPricing(SUBSCRIPTION_REASON.REFINE_PBR);
      }
    }
    this.onChange();
  }

  showPricing(action_id: SUBSCRIPTION_REASON) {
    this.dialog.open(PricingDialogComponent, {
      data: action_id
    });
  }

  async toggleMenu(state: boolean) {
    // const old = this.isOpen;
    this.isOpen = state;
    // if (window.innerWidth < mobileThreshold) {
    //   if (!old && state) {
    //     document.querySelector('.advanced-placeholder').appendChild(document.querySelector('app-advanced-options .menu'));
    //   }
    //   else if (old && !state) {
    //     const menu = document.querySelector('.advanced-placeholder').children[0];
    //     if (menu)
    //       document.querySelector('app-advanced-options').appendChild(menu);
    //   }
    // }

    // if (window.innerWidth < mobileThreshold) {
    //   await this.utils.setTimeout(100);
    //   const rectParent = this.menu.nativeElement.parentElement.getBoundingClientRect();
    //   const rect = this.menu.nativeElement.getBoundingClientRect();
    //   this.menu.nativeElement.style.transform = `scaleY(1) translateY(${rectParent.bottom - rect.height + 35}px)`;
    // }
    // if (!this.isOpen) this.menu.nativeElement.style.transform = '';
  }

  async onChange() {
    await this.utils.setTimeout();
    switch (this.action) {
      case RECONSTRUCTION_ACTION.UPSCALE_IMAGE: {
        break;
      }
      case RECONSTRUCTION_ACTION.GENERATE_IMAGE: {
        break;
      }
      default: {

      }
    }
    this.syncActionDetails();
    this.onOptionsChange.next(this.options);
  }
}
