import { Component, OnDestroy } from '@angular/core';
import { AiTexturePromptComponent } from '../ai-texture-prompt/ai-texture-prompt.component';
import { GenerateService } from '../generate.service';
import { IReconstructJobUI, RECONSTRUCT_JOB_STATUS } from '../generate';
import { ViewCreationComponent } from '../view-creation/view-creation.component';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { IPlaygroundNotification } from '../../shared/enums';
import { MetaOptions } from '../../shared/seo/seo';
import { PlatformName } from '../../shared/constants';
import { environment } from '../../../environments/environment';
import { SeoService } from '../../shared/seo/seo.service';
import { AuthService } from '../../auth/auth.service';

@Component({
    selector: 'app-ai-texture-wrap',
    imports: [
        AiTexturePromptComponent,
        ViewCreationComponent,
        CommonModule,
    ],
    templateUrl: './ai-texture-wrap.component.html',
    styleUrl: './ai-texture-wrap.component.scss'
})
export class AiTextureWrapComponent implements OnDestroy {
  private _subs: Array<Subscription>;
  constructor(
    public generateService: GenerateService,
    private broadcaster: BroadcasterService,
    private seo: SeoService,
    public auth: AuthService
  ) {
    this.generateService.creation = null;
    this._subs = [];
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe((data: any) => {
        this.onAnnouncement(data);
      })
    );
    this._subs.push(
      this.broadcaster.on('onPublish').subscribe((data: any) => {
        this.onPublish(data);
      })
    );
    let mOptions = new MetaOptions();
    mOptions.title = `${PlatformName} | AI Texture`;
    // mOptions.keywords = `${PlatformName}, Free, 3D Models, AI Texture`;
    mOptions.keywords = `AI texture generator, 3D model texture editor, free texture generator, text to texture, image based texture generator, AI texture tool, 3D model texture design, custom 3D textures, texture replacement`;
    mOptions.description = `${mOptions.description} On this page you can texture existing models with AI.`;
    mOptions.canonical = `${environment.domain}ai-texture`;
    this.seo.setMetaDate(mOptions);
    // this.pixels.sendPixel({
    //   event: 'pageView'
    // });
  }

  async onPublish(data: IReconstructJobUI) {
    // if (this.generateService.latestUpdated?.id === data.id) {
    //   this.generateService.latestUpdated = data;
    // }
  }

  onAnnouncement(data: IPlaygroundNotification) {
    this.generateService.counter++;
  }

  onSelect(item: IReconstructJobUI) {
    if (item.status === RECONSTRUCT_JOB_STATUS.Completed)
      this.generateService.creation = item;
    this.generateService.counter++;
  }

  async setPreview(imageURL: string) {
    // this.generateService.similarItems = await this.generateService.getSimilarProducts(imageURL);
  }

  ngOnDestroy() {
    this.generateService.counter = 0;
    this._subs.forEach(f => f.unsubscribe());
  }
}
