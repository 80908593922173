<div class="wrap disp-flex mobile-col-reverse">
    <div class="prompt-wrap view-min-height">
        <div class="disp-flex prompt-inner">
            <app-prompt (on-private)="setPrivate($event)" [force-state]="GENERATE_TYPE.TEXT_TO_3D"></app-prompt>
            <div class="divider-wrap disp-flex hidden-mobile">
                <mat-divider class="w-100"></mat-divider>
            </div>
            <div class="component-wrap disp-flex align-items-center hidden-mobile">
                <h3 class="txt-sm bold capitalize no-marg flex-1">my creations</h3>
                <a [routerLink]="['/creations']" class="txt-xxs link bold">
                    view all
                </a>
            </div>
            <app-generated-images (onSelect)="onImageSelect($event)"
                [type]="IMAGE_LIST_TYPE.HORIZONTAL_MOBILE"></app-generated-images>
        </div>
    </div>
    @if (generateService.reconstructedImage) {
    <div class="creation-wrap view-min-height">
        <app-view-generated-image class="disp-block h-100" [is-private]="isPrivate"
            [item]="generateService.reconstructedImage"></app-view-generated-image>
    </div>
    }
    @else if (isEmpty()) {
    <div class="creation-wrap view-min-height">
        <div class="h-100 disp-flex align-items-center">
            <div class="disp-flex col align-items-center center-me">
                <div class="space-bottom-md">
                </div>
                <div class="txt-md gray-50-color">
                    <div class="desc-wrap text-center">
                        <img src="/assets/images/generate-image.svg" alt="AI photos" class="space-bottom-md desc-img" />
                        <h1 class="ttl-xxs space-bottom-md">
                            Generate Images from Text Prompts with AI
                        </h1>
                        <div class="txt-xxs space-bottom-md lh text-start">
                            Transform your words into vivid, high-resolution images with our free online AI tool. Enter
                            a text prompt and let our advanced technology generate creative visuals perfect for artistic
                            projects, marketing, and social media.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    }
    @else {
    <div class="creation-wrap view-min-height" [ngClass]="{
        empty: isEmpty()
      }">
        @if (generateService.createdImages) {
        <!-- <div class="back-header hidden-desktop disp-flex">
            <a href="javascript:void(0)" (click)="generateService.createdImages = null">
                <img src="/assets/images/back.svg" alt="back" />
            </a>
            <div class="ttl-xs flex-def text-center">Preview images</div>
        </div> -->
        <app-generate-results class="disp-block view-min-height" [waiting-mode]="generateService.generatingImages"
            [job]="generateService.generatingImagesJob" [images]="generateService.createdImages"
            (on-completed)="onCompleted($event)" [is-private]="isPrivate"></app-generate-results>
        }
    </div>
    <div class="similar-wrap" [ngClass]="{ open: showSimilar }">
        @if (generateService.hasPreview()) {
        <button type="button" mat-raised-button class="btn btn-a inventory btn-img no-marg-img hidden-desktop"
            (click)="toggleSimilar()">
            <img src="/assets/images/marketplace-1.svg" alt="marketplace" />
        </button>
        <button type="button" mat-raised-button class="btn btn-c close-inventory btn-img no-marg-img hidden-desktop"
            (click)="toggleSimilar()">
            <img src="/assets/images/close.svg" alt="close" class="va-bottom" />
        </button>
        <app-similar class="disp-block" [items]="generateService.similarItems" (onSelect)="setSimilar($event)"
            (onClose)="showSimilar = false"></app-similar>
        }
    </div>
    }
</div>