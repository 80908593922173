import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { IAssetOptions } from '../enums';
import { MatButtonModule } from '@angular/material/button';
import { CommonModule } from '@angular/common';
import { DropdownButtonComponent } from '../dropdown-button/dropdown-button.component';
import { RECONSTRUCTION_ACTION } from '../../generate/generate';

@Component({
    selector: 'app-options-button',
    imports: [MatButtonModule, CommonModule, DropdownButtonComponent],
    templateUrl: './options-button.component.html',
    styleUrl: './options-button.component.scss'
})
export class OptionsButtonComponent implements OnChanges {
  @Input('css-class') cssClass: string;
  @Input() disabled: boolean;
  @Input() options: IAssetOptions[];
  @Input('current-action') currentAction: RECONSTRUCTION_ACTION;
  @Output() onClick = new EventEmitter<IAssetOptions>();
  @Output() onChange = new EventEmitter<IAssetOptions>();
  public value: IAssetOptions;
  public isOpen: boolean;
  constructor() {
    this.isOpen = false;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['currentAction']) this.setCurrent();
  }

  private setCurrent() {
    if (this.currentAction && this.options)
      this.value = this.options.find((kvp) => kvp.action === this.currentAction);
  }

  public onValueChange(value: IAssetOptions): void {
    this.value = value;
    this.onChange.emit(this.value);
  }

  click() {
    this.onClick.emit(this.value);
  }

  openChange(state: boolean) {
    this.isOpen = state;
  }
}
