import { Subject, Subscription } from "rxjs";
import { IReconstructionAction, IReconstructJobUI, RECONSTRUCT_JOB_STATUS } from "../generate/generate";
import { Injector } from "@angular/core";
import { GenerateService } from "../generate/generate.service";
import { UtilsService } from "./utils.service";
import { BroadcasterService } from "ng-broadcaster";
import { IPlaygroundNotification } from "./enums";

export class ProgressHelper {
    private __job: IReconstructJobUI;
    private __percentage: number;
    private __destroyed: boolean;
    private __initialized: boolean;
    private __action: IReconstructionAction;
    private __generateService: GenerateService;
    private __utils: UtilsService;
    private __broadcaster: BroadcasterService;
    private __subs: Array<Subscription>;
    public onJobChange: Subject<IReconstructJobUI>;
    constructor(
        private injectorInjected: Injector
    ) {
        this.__generateService = this.injectorInjected.get(GenerateService);
        this.__utils = this.injectorInjected.get(UtilsService);
        this.__broadcaster = this.injectorInjected.get(BroadcasterService);
        this.__subs = [];
        this.onJobChange = new Subject<IReconstructJobUI>;
    }

    get percentage() {
        return this.__percentage;
    }

    get action() {
        return this.__action;
    }

    get job() {
        return this.__job;
    }

    set job(value: IReconstructJobUI) {
        if (value)
            this.__destroyed = false;
        let doInit = false, statusChange = false;
        if (!this.__initialized || this.__job !== value)
            doInit = true;
        else if (this.__job?.status !== value?.status)
            statusChange = true;
        this.__job = value;
        if (!this.__job)
            this.__percentage = 0;
        if (doInit)
            this.__init();
        else if (statusChange)
            this.refreshProgress();
    }

    private async __init() {
        this.__subscribe();
        this.refreshProgress();
    }

    async refreshProgress() {
        await this.__setAction();
        this.__calcPercentage();
    }

    private __subscribe() {
        if (!this.__subs.length) {
            this.__subs.push(
                this.__broadcaster
                    .on('onWebsocketOpen')
                    .subscribe(this.__onConnection.bind(this))
            );

            this.__subs.push(
                this.__broadcaster.on('onAnnouncement').subscribe(async (d: any) => {
                    const data = d as IPlaygroundNotification;
                    if (this.job && data.job_id === this.job.id) {
                        if (this.job.status !== data.status) {
                            await this.__setAction();
                            this.job.status = data.status;
                            if (this.job.status === RECONSTRUCT_JOB_STATUS.Completed)
                                this.onCompleted();
                        }
                        if (this.job.status === RECONSTRUCT_JOB_STATUS.Failed)
                            this.onFailed();
                        this.__calcPercentage();
                        this.onJobChange.next(this.job);
                    }
                })
            );
        }
    }

    // This method can be override by the extending class
    onCompleted() {

    }

    // This method can be override by the extending class
    onFailed() {

    }

    private async __setAction() {
        if (!this.job) return;
        this.__action = await this.__generateService.getAction(this.job.action_id);
    }

    private async __calcPercentage() {
        await this.__utils.setTimeout();
        if (this.__destroyed) return;
        if (!this.__action)
            await this.__setAction();
        if (
            this.__action &&
            this.job &&
            this.job.status === RECONSTRUCT_JOB_STATUS['In Progress']
        ) {
            if (this.__action.id !== this.job.action_id)
                await this.__setAction();
            setTimeout(this.__calcPercentage.bind(this));
            // console.log('app-creation-card', this.action);
            this.__percentage = this.__utils.calcActionPercentage(this.job, this.__action);
        }
    }

    private async __onConnection() {
        if (this.__destroyed) return;
        if (
            this.job &&
            this.job.status !== RECONSTRUCT_JOB_STATUS.Completed &&
            this.job.status !== RECONSTRUCT_JOB_STATUS.Failed
        ) {
            this.job = await this.__generateService.updateJob(this.job);
            this.onJobChange.next(this.job);
            this.__calcPercentage();
        }
    }

    public destroyProgress() {
        this.__destroyed = true;
        this.__subs.forEach(s => s.unsubscribe());
        this.__subs = [];
    }
}