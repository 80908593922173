import { Component, EventEmitter, Injector, OnInit, Output } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { CommonModule } from '@angular/common';
import { IReconstructionAction, IReconstructJob, RECONSTRUCTION_ACTION, SUBSCRIPTION_REASON } from '../generate';
import { GenerateService } from '../generate.service';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { ProgressHelper } from '../../shared/progress-helper';
import { UtilsService } from '../../shared/utils.service';
import { BroadcasterService } from 'ng-broadcaster';
import { AuthService } from '../../auth/auth.service';
import { PixelsService } from '../../shared/pixels.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { ScreenNotificationType } from '../../shared/enums';
import { MatButtonModule } from '@angular/material/button';
import { MAX_UPSCALE_DIM, PRIVATE_DESC } from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { PricingDialogComponent } from '../../pricing/pricing-dialog/pricing-dialog.component';
import { Subscription } from 'rxjs';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-upscale',
  imports: [MatProgressBarModule, CommonModule, CostEstComponent, MatButtonModule, RouterLink, MatTooltipModule],
  templateUrl: './upscale.component.html',
  styleUrl: './upscale.component.scss'
})
export class UpscaleComponent extends ProgressHelper implements OnInit {
  @Output('on-private') onPrivate: EventEmitter<boolean>;
  private _isPrivate: boolean;
  private _onSubscriptionUpdated: Subscription;
  public imageToLoad: string;
  public loadingImageFiles: boolean;
  public usAction: IReconstructionAction;
  public imageDim: number;
  public maxDim: number;
  public showLogin: boolean;
  public oneTapFailure: boolean;
  public ttPrivate: string;
  constructor(
    private resumableUploadService: ResumableUploadService,
    private generateService: GenerateService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService,
    private injector: Injector,
    public auth: AuthService,
    private pixels: PixelsService,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
  ) {
    super(injector);
    this.ttPrivate = PRIVATE_DESC;
    this.onPrivate = new EventEmitter<boolean>();
    this.init();
  }

  ngOnInit() {
    const params = this.activatedRoute.snapshot.params as any;
    if (params?.cid)
      this.duplicate(parseInt(params.cid), parseInt(params.iid));
  }

  async duplicate(id: number, imgId: number) {
    if (this.auth.isloggedIn()) {
      if (id && !isNaN(id)) {
        const job = (await this.generateService.getJobById(id)).data.reconstruction_jobs;
        if (imgId && !isNaN(imgId))
          this.imageToLoad = job.reconstruction_images.find(ri => ri.id === imgId).url;
        else
          this.imageToLoad = job.reconstruction_images[0].url;
        let d = await this.utils.getImageDim(this.imageToLoad);
        this.imageDim = Math.max(d.x, d.y);
        this.imageDim = Math.max(this.imageDim, d.z);
      }
    }
  }

  async init() {
    this.maxDim = MAX_UPSCALE_DIM;
    this.usAction = (
      await this.generateService.getAction(RECONSTRUCTION_ACTION.UPSCALE_IMAGE)
    );
  }

  async onImageFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.loadingImageFiles = true;
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp')
        continue;
      this.imageToLoad = await this.utils.getBase64FromFile(file);
      let d = await this.utils.getImageDim(this.imageToLoad);
      this.imageDim = Math.max(d.x, d.y);
      this.imageDim = Math.max(this.imageDim, d.z);
      if (this.imageDim > this.maxDim) {
        this.loadingImageFiles = false;
        break;
      }
      const imageToLoad = await this.resumableUploadService.file(file);
      this.imageDim = MAX_UPSCALE_DIM + 1;
      d = await this.utils.getImageDim(imageToLoad);
      this.imageDim = Math.max(d.x, d.y);
      this.imageDim = Math.max(this.imageDim, d.z);
      this.imageToLoad = imageToLoad;
      break;
    }
    event.srcElement.value = '';
    this.loadingImageFiles = false;
  }

  async generate() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'upscale_2d',
      sub_click_type: 'upscale_from_component',
    });
    if (!await this.auth.isloggedIn()) {
      this.showLogin = true;
      try {
        await this.auth.oneTapAuth();
        this.showLogin = false;
        this.generate();
      } catch (e) {
        this.oneTapFailure = true;
      }
    }
    else {
      if (!this.generateService.checkCreditsAndPrompt(this.usAction.credits, true))
        return;
      if (this.generateService.validateSubscription(this.usAction)) {
        this.job = await this.generateService.upscaleImage({
          private: this.isPrivate,
          src: this.imageToLoad
        }, (job: IReconstructJob) => {
          // this.broadcaster.broadcast('onPublish', job);
          // this.utils.forceRedirectTo(`/creations/${job.id}`);
          if (job.reconstruction_images?.length)
            this.router.navigateByUrl(`/creations/${job.id}/${job.reconstruction_images[0].id}`);
        });
        this.utils.notifyUser({
          type: ScreenNotificationType.Neutral,
          text: 'upscaling . . .',
        });
      }
      else {
        this.dialog.open(PricingDialogComponent, {
          data: RECONSTRUCTION_ACTION.UPSCALE_IMAGE
        });
      }
    }
  }

  get isPrivate() {
    return this._isPrivate;
  }

  set isPrivate(value: boolean) {
    const setIsPrivate = (v: boolean) => {
      this._isPrivate = v;
      this.onPrivate.next(this.isPrivate);
    };
    if (value && !this.generateService.canRequestPrivate()) {
      this.showPricing(SUBSCRIPTION_REASON.PRIVATE);
      this._onSubscriptionUpdated = this.broadcaster.on('subscriptionUpdated').subscribe(() => {
        if (this.auth.subscription)
          setIsPrivate(true);
        this._onSubscriptionUpdated.unsubscribe();
      });
    }
    else
      setIsPrivate(value);
  }

  showPricing(action_id: SUBSCRIPTION_REASON) {
    this.dialog.open(PricingDialogComponent, {
      data: action_id
    });
  }
}
