<div class="wrap disp-flex" [ngClass]="{'sm': seperateMobile}">
  <span class="disable-color cost">
    Cost:
    <span class="default-color bold disp-inline-block"
      ><img class="btn-icon" src="/assets/images/coin.svg" alt="coin" />
      {{cost}}</span
    >
  </span>
  <span class="disable-color align-end-flex flex-1 text-end est">
    Est. time: <span class="default-color bold disp-inline-block">{{estDisplay}} {{time}}</span>
  </span>
</div>