import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { EnumsService } from '../../shared/enums.service';
import { GenerateService } from '../generate.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { KeyValuePair } from 'asset-adjustments';
import { GENERATE_TYPE, IAdvancedOptions, ICreateVideoOptions, IEditImagesOptions, IReconstructionAction, RECONSTRUCTION_ACTION, SUBSCRIPTION_REASON, VIDEO_METHOD, VIDEO_TRAJECTORY } from '../generate';
import { UtilsService } from '../../shared/utils.service';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { PixelsService } from '../../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { AuthService } from '../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PricingDialogComponent } from '../../pricing/pricing-dialog/pricing-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ScreenNotificationType } from '../../shared/enums';
import { MatSelectModule } from '@angular/material/select';
import { Generate2dHelperService } from '../generate-2d-helper.service';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { RolesHelperService } from '../../auth/roles-helper.service';
import { IMAGE_SAMPLES } from '../../shared/constants';

@Component({
  selector: 'app-edit-image',
  imports: [
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    CostEstComponent,
    MatProgressBarModule,
    MatTooltipModule,
    MatSelectModule,
    RouterLink
  ],
  templateUrl: './edit-image.component.html',
  styleUrl: './edit-image.component.scss'
})
export class EditImageComponent implements OnInit {
  @Output('on-private') onPrivate: EventEmitter<boolean>;
  private _examplePromptIndex: number;
  private _afterFilesChange: Array<Function>;
  private _isPrivate: boolean;
  private _onSubscriptionUpdated: Subscription;
  public imageToUpload: Array<string>;
  public uploadingImage: boolean;
  public actionDetails: IReconstructionAction;
  public action: RECONSTRUCTION_ACTION;
  public uploadImagesID = 'upload-images';
  public advancedOptions: IAdvancedOptions;
  public ttPrivate: string;
  public GENERATE_TYPE = GENERATE_TYPE;
  public videoMethod: VIDEO_METHOD;
  public VIDEO_METHOD = VIDEO_METHOD;
  public trajectoryTypes: Array<KeyValuePair>;
  public trajectoryType: VIDEO_TRAJECTORY;
  public showLogin: boolean;
  public oneTapFailure: boolean;
  public isSU: boolean;
  constructor(
    private utils: UtilsService,
    private uploadService: ResumableUploadService,
    public generateService: GenerateService,
    private pixels: PixelsService,
    private broadcaster: BroadcasterService,
    private enums: EnumsService,
    public auth: AuthService,
    private dialog: MatDialog,
    public generate2dHelper: Generate2dHelperService,
    private roles: RolesHelperService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.videoMethod = null;
    this.imageToUpload = [];
    this._afterFilesChange = [];
    this.trajectoryTypes = this.enums.getAllVideoTrajectories();
    this._examplePromptIndex = 0;
    this.ttPrivate = 'By toggling private mode on you can prevent this model from being shown and download on the community. The model IP is yours.';
    this.onPrivate = new EventEmitter<boolean>();
    switch (location.pathname.split('\/')[1]) {
      case 'free-online-ai-photo-editor': {
        this.generate2dHelper.type = GENERATE_TYPE.EDIT_IMAGE;
        break;
      }
      case 'online-ai-video-generator': {
        this.generate2dHelper.type = GENERATE_TYPE.CREATE_VIDEO;
        break;
      }
    }
    this.init();
  }

  get isPrivate() {
    return this._isPrivate;
  }

  set isPrivate(value: boolean) {
    const setIsPrivate = (v: boolean) => {
      this._isPrivate = v;
      this.advancedOptions.private = this.isPrivate;
      this.onPrivate.next(this.isPrivate);
    };
    if (value && !this.generateService.canRequestPrivate()) {
      this.showPricing(SUBSCRIPTION_REASON.PRIVATE);
      this._onSubscriptionUpdated = this.broadcaster.on('subscriptionUpdated').subscribe(() => {
        if (this.auth.subscription)
          setIsPrivate(true);
        this._onSubscriptionUpdated.unsubscribe();
      });
    }
    else
      setIsPrivate(value);
  }

  showPricing(action_id: SUBSCRIPTION_REASON) {
    this.dialog.open(PricingDialogComponent, {
      data: action_id
    });
  }

  private async init() {
    this.setActionDetails();
  }

  ngOnInit() {
    let params = this.utils.getAngularUrlParams();
    if (params?.duplicate)
      this.duplicate(parseInt(params.duplicate));
    params = this.activatedRoute.snapshot.params as any;
    if (params?.cid)
      this.duplicate(parseInt(params.cid), parseInt(params.iid));
    this.isSU = this.roles.isRoleLogedin('Super User');
  }

  async setActionDetails() {
    this.action = this.generate2dHelper.type === GENERATE_TYPE.CREATE_VIDEO ? RECONSTRUCTION_ACTION.GENERATE_VIDEO : RECONSTRUCTION_ACTION.GENERATE_IMAGE;
    this.actionDetails = (
      await this.generateService.getAction(this.action)
    );
  }

  // async setState(state: GENERATE_TYPE) {
  //   await this.setActionDetails();
  // }

  async duplicate(id: number, imgId?: number) {
    if (id && !isNaN(id)) {
      const job = (await this.generateService.getJobById(id)).data.reconstruction_jobs;
      if (imgId && !isNaN(imgId)) {
        this.imageToUpload = [job.reconstruction_images.find(ri => ri.id === imgId).url];
      }
      else {
        switch (job.source_action_id || job.action_id) {
          case RECONSTRUCTION_ACTION.RECONSTRUCTION_FROM_TEXT: {
            this.generateService.text = job.text;
            break;
          }
          case RECONSTRUCTION_ACTION.RECONSTRUCTION: {
            // this.state = this.IMAGE_TO_3D;
            this.generateService.text = job.reconstruction_jobs_inputs[0].text_input;
            this.imageToUpload = job.reconstruction_jobs_inputs.map(r => r.image_url);
            if (!this.imageToUpload.length)
              this.imageToUpload[0] = job.preview;
            break;
          }
        }
      }
      // this.setActionDetails();
    }
  }

  async generate() {
    this.sendClickPixel();
    if (!await this.auth.isloggedIn()) {
      this.showLogin = true;
      try {
        await this.auth.oneTapAuth();
        this.showLogin = false;
        this.generate();
      } catch (e) {
        this.oneTapFailure = true;
      }
    }
    else {
      switch (this.generate2dHelper.type) {
        case GENERATE_TYPE.CREATE_VIDEO: {
          this.generateVIdeo();
          break;
        }
        case GENERATE_TYPE.EDIT_IMAGE: {
          this.generateImage();
          break;
        }
      }
    }
  }

  sendClickPixel() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_2d',
      sub_click_type: this.generate2dHelper.type === GENERATE_TYPE.CREATE_VIDEO ? 'create_video' : 'edit_image',
    });
  }

  async generateVIdeo() {
    if (!this.actionDetails) return;
    if (!this.generateService.checkCreditsAndPrompt(this.actionDetails.credits, true))
      return;
    if (!this.generateService.validateSubscription(this.actionDetails)) {
      this.showPricing(SUBSCRIPTION_REASON.GENERATE_VIDEO);
      return;
    }
    const payload = {
      prompt: this.generateService.text,
      images: this.imageToUpload,
      public: !this.isPrivate
    } as ICreateVideoOptions;
    // this.getSimilarFromText();
    if (this.videoMethod === VIDEO_METHOD.SVC) {
      payload.prompt = '';
      payload.trajectory_type = this.trajectoryType;
      payload.resolution = 720;
      payload.method = this.videoMethod;
    }
    this.generateService.videoCreation = await this.generateService.createVideo(payload);
    this.utils.notifyUser({
      type: ScreenNotificationType.Neutral,
      text: 'creating video . . .',
    });
    this.generateService.text = null;
    this.broadcaster.broadcast('onGenerating', this.generateService.videoCreation);
  }

  async generateImage() {
    if (!this.actionDetails) return;
    if (!this.generateService.checkCreditsAndPrompt(this.actionDetails.credits, true))
      return;
    if (!this.generateService.validateSubscription(this.actionDetails)) {
      this.showPricing(SUBSCRIPTION_REASON.GENERATE_IMAGE);
      return;
    }
    const payload = {
      prompt: this.generateService.text,
      images: this.imageToUpload,
      samples: IMAGE_SAMPLES,
      public: !this.isPrivate
    } as IEditImagesOptions;
    // this.getSimilarFromText();
    this.generateService.reconstructedImage = null;
    const job = await this.generateService.editImages(payload);
    this.utils.notifyUser({
      type: ScreenNotificationType.Neutral,
      text: 'editing image . . .',
    });
    this.generateService.text = null;
    this.broadcaster.broadcast('onGenerating', job);
  }

  async onFilesChange(event: any) {
    if (this.uploadingImage) {
      this._afterFilesChange.push(this.onFilesChange.bind(this), event);
      return;
    }

    this.uploadingImage = true;

    // let base64Failures = {} as { [id: number]: boolean };
    const initialIndex = this.imageToUpload.length;
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp')
        continue;
      try {
        const b64 = await this.utils.getBase64FromFile(file);
        if (b64)
          this.imageToUpload.push(b64);
        // else
        //   base64Failures[i] = true;
      } catch (e) {
        // base64Failures[i] = true;
      }
    }
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp')
        continue;
      const url = await this.uploadService.file(file);
      // if (!base64Failures[i])
      //   this.imageToUpload[i] = url;
      // else
      //   this.imageToUpload.push(url);
      this.imageToUpload[initialIndex + i] = url;
    }
    event.srcElement.value = '';
    this.uploadingImage = false;

    if (this._afterFilesChange.length)
      this._afterFilesChange.pop()();
  }

  isDisable() {
    switch (this.generate2dHelper.type) {
      case GENERATE_TYPE.CREATE_VIDEO: {
        if (this.videoMethod === VIDEO_METHOD.SVC)
          return !this.imageToUpload[0] || !!this.uploadingImage || !this.trajectoryType
        return (
          !this.imageToUpload[0] ||
          !!this.uploadingImage ||
          !this.generateService.text
        );
      }
      default: {
        return (
          !this.imageToUpload[0] ||
          !!this.uploadingImage ||
          !this.generateService.text
        );
      }
    }
  }

  examplePrompt() {
    const prompts = this.enums.getExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.generateService.text = p;
  }

  async setExampleImage() {
    // this.imageToUpload.push('https://himg-cdn.com/hotlink-ok/20250225/5f69eda56dfc48eaa13ac3196225afab.png');
    this.imageToUpload.push('https://himg-cdn.com/hotlink-ok/20250203/7f13f95adff14291b0f8edf74187a77c.webp');
    if (!this.generateService.text)
      this.generateService.text = 'Chairs & Stools';
    // this.generateService.text =
    //   await this.generateService.getImageClassification(
    //     this.imageToUpload[0],
    //     true
    //   );
  }

  addMoreImages() {
    document.getElementById(this.uploadImagesID).click();
  }

  removeImage(index: number) {
    this.imageToUpload.splice(index, 1);
  }

  getSliderWidth() {
    if (this.imageToUpload.length > 1)
      return 'calc(' + this.imageToUpload.length + '*86px)';
    return '82px';
  }

  // onOptionsChange(options: IAdvancedOptions) {
  //   this.advancedOptions = options;
  //   // this.advancedOptions.is_private = this.isPrivate;
  // }

  ngOnDestroy() {
    this.generateService.resetAll();
    this._onSubscriptionUpdated?.unsubscribe();
  }
}
