<div class="wrap disp-flex mobile-col-reverse">
  <div class="prompt-wrap view-min-height">
    <div class="disp-flex col prompt-inner">
      <app-ai-texture-prompt></app-ai-texture-prompt>
      <!-- @if (generateService.latestUpdated) {
      <div class="component-wrap">
        <h3 class="txt-xxs bold capitalize-fl no-marg flex-1 hidden-mobile space-bottom-xs-mobile">
          current job
        </h3>
        <app-creation-card
          (onSelect)="onSelect($event)"
          [item]="generateService.latestUpdated"
          [counter]="generateService.counter"
        ></app-creation-card>
      </div>
      } -->
    </div>
  </div>
  <div class="flex-1 view-min-height creation-wrap align-content-center" [ngClass]="{
      empty: !generateService.creation
    }">
    @if(generateService.creation) {
    <app-view-creation class="disp-block h-100" [creation]="generateService.creation"
      (onPreview)="setPreview($event)"></app-view-creation>
    }
    @else {
    <div class="h-100 disp-flex align-items-center">
      <div class="disp-flex col align-items-center center-me">
        <div class="space-bottom-md">
          <!-- @if (auth.user) {
          <img src="/assets/images/cube.svg" alt="3D model" class="input-type">
          } -->
        </div>
        <div class="txt-md gray-50-color">
          <!-- @if (auth.user) {
          @if (generateService.textureState === generateService.TEXTURE_GENERATE_TYPE.EXISTING) {
          Select model from library
          }
          @else {
          Upload model
          }
          }
          @else { -->
          <div class="desc-wrap text-center">
            <img src="/assets/images/ai-texture.svg" alt="3D" class="space-bottom-md desc-img" />
            <h1 class="ttl-xxs space-bottom-md">
              Generate Textures from Text Prompts or Images with AI.
            </h1>
            <div class="txt-xxs space-bottom-md lh text-start">
              Revitalize your 3D models with new, custom textures using our free online AI tool. Upload your 3D model
              along with a text prompt or image to generate realistic and creative textures—all while preserving your
              model’s original geometry.
            </div>
          </div>
          <!-- } -->
        </div>
      </div>
    </div>
    }
  </div>
</div>