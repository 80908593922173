import { Component } from '@angular/core';
import { EditImageComponent } from '../edit-image/edit-image.component';
import { GeneratedImagesComponent } from '../generated-images/generated-images.component';
import { MatDividerModule } from '@angular/material/divider';
import { ViewGeneratedImageComponent } from '../view-generated-image/view-generated-image.component';
import { GenerateService } from '../generate.service';
import { CommonModule } from '@angular/common';
import { GENERATE_TYPE, IMAGE_LIST_TYPE, IReconstructImageSelect, IReconstructJobUI } from '../generate';
import { RouterLink } from '@angular/router';
import { Generate2dHelperService } from '../generate-2d-helper.service';
import { GeneratedVideosComponent } from '../generated-videos/generated-videos.component';
import { ViewGeneratedVideoComponent } from '../view-generated-video/view-generated-video.component';
import { AuthService } from '../../auth/auth.service';
import { MatTooltipModule } from '@angular/material/tooltip';

@Component({
  selector: 'app-edit-image-wrap',
  imports: [EditImageComponent, GeneratedImagesComponent, MatDividerModule, ViewGeneratedImageComponent, CommonModule, RouterLink, EditImageComponent, GeneratedVideosComponent, ViewGeneratedVideoComponent, MatTooltipModule],
  templateUrl: './edit-image-wrap.component.html',
  styleUrl: './edit-image-wrap.component.scss'
})
export class EditImageWrapComponent {
  public IMAGE_LIST_TYPE = IMAGE_LIST_TYPE;
  public GENERATE_TYPE = GENERATE_TYPE;
  public isPrivate: boolean;
  constructor(
    public generateService: GenerateService,
    public generate2dHelper: Generate2dHelperService,
    public auth: AuthService
  ) {

  }

  isEmpty() {
    return !this.generateService.reconstructedImage && !this.generateService.videoCreation && !this.generateService.generatingImagesJob;
  }

  onImageSelect(item: IReconstructImageSelect) {
    this.generateService.reconstructedImage = item.image;
    this.generateService.creation = item.job;
  }

  onVideoSelect(item: IReconstructJobUI) {
    this.generateService.videoCreation = item;
  }

  setPrivate(value: boolean) {
    this.isPrivate = value;
  }
}
