<div class="wrap disp-flex mobile-col-reverse">
    <div class="prompt-wrap view-min-height">
        <div class="disp-flex prompt-inner">
            <app-edit-image (on-private)="setPrivate($event)"></app-edit-image>
            <div class="divider-wrap disp-flex hidden-mobile">
                <mat-divider class="w-100"></mat-divider>
            </div>
            <div class="component-wrap disp-flex align-items-center hidden-mobile">
                <h3 class="txt-sm bold capitalize no-marg flex-1">my creations</h3>
                <a [routerLink]="['/creations']" class="txt-xxs link bold">
                    view all
                </a>
            </div>
            @if (generate2dHelper.type === GENERATE_TYPE.CREATE_VIDEO) {
            <app-generated-videos class="elem" (onSelect)="onVideoSelect($event)"
                [type]="IMAGE_LIST_TYPE.HORIZONTAL_MOBILE"></app-generated-videos>
            }
            @else {
            <app-generated-images class="elem" (onSelect)="onImageSelect($event)"
                [type]="IMAGE_LIST_TYPE.HORIZONTAL_MOBILE"></app-generated-images>
            }
        </div>
    </div>
    @if (isEmpty()) {
    <div class="creation-wrap view-min-height">
        <div class="h-100 disp-flex align-items-center">
            <div class="disp-flex col align-items-center center-me">
                <div class="space-bottom-md">
                </div>
                <div class="txt-md gray-50-color">
                    @if (generate2dHelper.type === GENERATE_TYPE.EDIT_IMAGE) {
                    <div class="desc-wrap text-center">
                        <img src="/assets/images/magic-wang.svg" alt="AI photos" class="space-bottom-md desc-img" />
                        <h1 class="ttl-xxs space-bottom-md">
                            Edit, Enhance & Reimagine Your Photos with AI
                        </h1>
                        <div class="txt-xxs space-bottom-md text-start">
                            Transform your photos effortlessly with our free online AI image editor. Upload any image,
                            add a custom text prompt, and instantly generate high-resolution, realistic, or artistic
                            versions. Perfect for creative projects, social media, and professional design needs.
                        </div>
                        <div class="txt-xxs space-bottom-md text-start disable-color">
                            <img class="va-bottom filter-disabled" matTooltip="experimental feature"
                                src="/assets/images/experiment.svg" alt="experimental"> experimental feature
                        </div>
                    </div>
                    }
                    @else {
                    <div class="desc-wrap text-center">
                        <img src="/assets/images/video-creation.svg" alt="video generation"
                            class="space-bottom-md desc-img" />
                        <h1 class="ttl-xxs space-bottom-md">
                            Convert Images into Engaging Videos with AI Technology
                        </h1>
                        <div class="txt-xxs space-bottom-md text-start">
                            Transform a single image into a captivating video with our free online AI video generator.
                            Upload an image, add a text prompt, and instantly create dynamic, high-resolution videos
                            perfect for social media, marketing, and creative projects.
                        </div>
                        <div class="txt-xxs space-bottom-md text-start disable-color">
                            <img class="va-bottom filter-disabled" matTooltip="experimental feature"
                                src="/assets/images/experiment.svg" alt="experimental"> experimental feature
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
    }
    @else {
    <div class="creation-wrap view-min-height" [ngClass]="{
        empty: isEmpty()
      }">
        @if(generateService.reconstructedImage || generateService.generatingImagesJob) {
        <app-view-generated-image class="disp-block h-100" [job-item]="generateService.generatingImagesJob"
            [is-private]="isPrivate" [item]="generateService.reconstructedImage"></app-view-generated-image>
        }
        @else if(generateService.videoCreation) {
        <app-view-generated-video class="disp-block h-100"
            [item]="generateService.videoCreation"></app-view-generated-video>
        }
    </div>
    }
</div>