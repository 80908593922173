import { Component, OnDestroy } from '@angular/core';
import { GeneratedImagesComponent } from '../generated-images/generated-images.component';
import { IMAGE_LIST_TYPE, IReconstructImage, IReconstructImageSelect, ISimilarItem, RECONSTRUCTION_ACTION } from '../generate';
import { GenerateService } from '../generate.service';
import { CommonModule } from '@angular/common';
import { ViewGeneratedImageComponent } from '../view-generated-image/view-generated-image.component';
import { noPaddDialog } from '../../shared/constants';
import { MatDialog } from '@angular/material/dialog';
import { SimilarPreviewComponent } from '../similar-preview/similar-preview.component';
import { SimilarComponent } from '../similar/similar.component';
import { UpscaleComponent } from '../upscale/upscale.component';
import { MatButtonModule } from '@angular/material/button';
import { AuthService } from '../../auth/auth.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { MetaOptions } from '../../shared/seo/seo';
import { environment } from '../../../environments/environment';
import { SeoService } from '../../shared/seo/seo.service';

@Component({
  selector: 'app-upscale-wrap',
  imports: [GeneratedImagesComponent, CommonModule, ViewGeneratedImageComponent, SimilarComponent, UpscaleComponent, MatButtonModule],
  templateUrl: './upscale-wrap.component.html',
  styleUrl: './upscale-wrap.component.scss'
})
export class UpscaleWrapComponent implements OnDestroy {
  public IMAGE_LIST_TYPE = IMAGE_LIST_TYPE;
  public showSimilar: boolean;
  public actions: Array<RECONSTRUCTION_ACTION>;
  public isLogin: boolean;
  public isPrivate: boolean;
  private _subs: Array<Subscription>;
  constructor(
    public generateService: GenerateService,
    private dialog: MatDialog,
    private auth: AuthService,
    private broadcaster: BroadcasterService,
    private seo: SeoService
  ) {
    this.actions = [RECONSTRUCTION_ACTION.UPSCALE_IMAGE];
    this._subs = [];
    let mOptions = new MetaOptions();
    mOptions.title = `Online Image Resolution Enhancer – Instantly Upscale & Improve Photos`;
    mOptions.keywords = `Image Resolution Enhancer, Online Image Upscaler, High Resolution Photo Booster, Photo Quality Improvement, Image Enhancement Tool, Upscale Image Online, Resolution Improvement Software, Super Resolution, Online, Free, Lossless ,AI`;
    mOptions.description = `Transform your images into high-definition masterpieces with our online image resolution enhancer. Instantly upscale, improve photo quality, and add stunning clarity—perfect for photographers, designers, and creators. The online tool is free to use.`;
    mOptions.canonical = `${environment.domain}free-online-image-upscale`;
    this.seo.setMetaDate(mOptions);
    this.init();
  }

  async init() {
    this.isLogin = await this.auth.isloggedIn();
    if (!this.isLogin)
      this._subs.push(this.broadcaster.on('onLogin').subscribe(() => this.isLogin = true));
  }

  async onImageSelect(item: IReconstructImageSelect) {
    this.generateService.reconstructedImage = item.image;
    this.generateService.creation = item.job;
    this.generateService.similarItems = await this.generateService.getSimilarProducts(item.image.url);
    this.showSimilar = true;
  }

  hasContent() {
    return this.generateService.reconstructedImage;
  }

  setSimilar(similar: ISimilarItem) {
    document.body.classList.add(noPaddDialog);
    this.dialog.open(SimilarPreviewComponent, {
      data: similar,
    });
    this.dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove(noPaddDialog);
    });
  }

  setPrivate(value: boolean) {
    this.isPrivate = value;
  }

  toggleSimilar() {
    this.showSimilar = !this.showSimilar;
  }

  ngOnDestroy() {
    this._subs.forEach(s => s.unsubscribe());
  }
}
