<div class="wrap" [ngClass]="{
    'horizontal-mobile': type === IMAGE_LIST_TYPE.HORIZONTAL_MOBILE
  }">
  @if (!giService.items) {
  @if (!isLoggedIn) {
  <div class="empty-wrap text-center h-100 disp-flex col pc-center-center align-items-center">
    <div class="disable-color txt-sm">Login to view your images</div>
  </div>
  }
  @else {
  <div class="empty-wrap text-center h-100 disp-flex col pc-center-center align-items-center">
    <img src="/assets/images/loading.gif" alt="asset" />
    <div class="desc txt-sm">Loading . . .</div>
  </div>
  }
  } @else if (!giService.items.length) {
  <div class="empty-wrap text-center h-100 disp-flex col pc-center-center align-items-center">
    <div class="desc txt-sm disable-color">No creations yet</div>
  </div>
  } @else {
  <div class="list disp-flex row" #scroll id="creation-images-list">
    @for (job of giService.items; track $index) {
    @for (item of job.reconstruction_images; track $index) {
    <div class="item">
      <app-generated-image-card (click)="setImage(item, job)" class="content-va disp-block pointer" [item]="item"
        [prompt]="getPrompt(job)"></app-generated-image-card>
    </div>
    }
    }
  </div>
  }
</div>