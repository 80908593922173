<header class="component-wrap">
  <div class="logo-wrap">
    <a routerLink="/" class="link text-decoration-none">
      <app-hexa-logo></app-hexa-logo>
    </a>
  </div>
  <div class="links hidden-mobile">
    <!-- @if (isloggedIn) { -->
    <button mat-raised-button class="btn btn-d pos-rel generate-btn">
      <span class="text-gradient">
        generate <span class="arrow disp-inline-block text-gradient">^</span>
      </span>
      <div class="generate-wrap pos-abs disp-none">
        <div class="generate-inner">
          <div class="section">
            <h3 class="txt-xxs">3D Generation</h3>
            <div class="generate-links">
              <app-generate-header-link link="free-online-ai-text-to-3d-model-generator" img="/assets/images/text.svg"
                text="Text to 3D" subtext="Convert text prompt into 3D model"></app-generate-header-link>
              <app-generate-header-link link="free-online-ai-3d-image-to-model-generator"
                img="/assets/images/picture.svg" text="Image to 3D"
                subtext="Upload image and convert into 3D model"></app-generate-header-link>
              <app-generate-header-link link="free-online-ai-texture-generator" img="/assets/images/ai-texture.svg" text="AI-texturing"
                subtext="Create a new texture for your models"></app-generate-header-link>
            </div>
          </div>
          <div class="section">
            <h3 class="txt-xxs">2D Generation</h3>
            <div class="generate-links">
              <app-generate-header-link link="free-online-image-upscale" img="/assets/images/image-upscale.svg"
                text="Image upscale" subtext="Bosts resolution and enhances quality"></app-generate-header-link>
              <app-generate-header-link link="free-online-ai-photo-editor" img="/assets/images/magic-wang.svg"
                text="Image manipulation" subtext="Edit images via text prompt"></app-generate-header-link>
              <app-generate-header-link link="online-ai-video-generator" img="/assets/images/video-creation.svg"
                text="Video creation"
                subtext="Create a video from an image and a text prompt"></app-generate-header-link>
              <app-generate-header-link link="free-online-ai-text-to-image-generator" img="/assets/images/generate-image.svg"
                text="Image creation" subtext="Create images from a text prompt"></app-generate-header-link>
            </div>
          </div>
        </div>
      </div>
    </button>
    @if (!isloggedIn) {
    <a [routerLink]="['/login']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('login')">login</a>
    }
    @else {
    <a [routerLink]="['/creations']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('creations')">my creations</a>
    }
    <!-- @if (isSU) { -->
    <!-- } -->
    <a [routerLink]="['/pricing']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('pricing')">buy credits</a>
    <!-- } -->
    <!-- @else { -->
    <!-- @if (isSU) {
    <a [routerLink]="['/free-online-image-upscale']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('free-online-image-upscale')">upscale</a>
    }
    <a [routerLink]="['/pricing']" mat-raised-button class="btn btn-d" routerLinkActive="active"
      (click)="onAction('pricing')">pricing</a> -->
    <!-- } -->
  </div>
  <div class="personal text-end pos-rel align-end-flex hidden-mobile" id="users-summary">
    @if (isloggedIn) {
    <a class="balance txt-sm bold pointer td-none" [routerLink]="['/pricing']" (click)="onAction('pricing')">
      <!-- (click)="this.toggleCreditsOptions(!this.showCreditsOptions)"  -->
      <img src="/assets/images/coin.svg" alt="coin" class="va-middle" />
      {{ auth.credits | number }}
    </a>
    <button type="button" mat-raised-button class="btn btn-d no-marg-img btn-img notifications pos-rel"
      (click)="showNotifications = !showNotifications" [ngClass]="{ active: showNotifications }">
      <img src="/assets/images/notification.svg" alt="notification" />
      @if (notificationsService.unred) {
      <span class="unred pos-abs">{{notificationsService.unred > 9 ? '+9' : notificationsService.unred}}</span>
      }
    </button>
    <button type="button" mat-mini-fab class="btn btn-a account"
      [ngClass]="{ active: this.globals.showSelfProfile, 'has-img': !!auth.user.avatar_url }"
      (click)="toggleSelfProfile()" id="self-profile">
      @if (auth.user.avatar_url) {
      <img [src]="auth.user.avatar_url" alt="Avatar" />
      }{{ firstLetter }}
    </button>
    @if (showCreditsOptions) {
    <div class="credits-options text-start fade-in" (click)="this.toggleCreditsOptions(false)">
      <div class="ow">
        <a [routerLink]="['/topups']" class="btn btn-d disp-block w-100 text-start" mat-raised-button>add credits</a>
      </div>
      <div class="ow">
        <a [routerLink]="['/pricing']" class="btn btn-d disp-block w-100 text-start" mat-raised-button>change
          subscription plan</a>
      </div>
    </div>
    } @if (globals.showSelfProfile) {
    <div class="user-wrap pos-abs box-shadow fade-in text-start">
      <app-user-card class="disp-block" [user]="auth.user"></app-user-card>
    </div>
    } } @else {
    <a class="btn btn-a" mat-raised-button [routerLink]="['/login']" routerLinkActive="active"
      (click)="onAction('start_for_free')">start for free</a>
    }
  </div>
  @if (showNotifications) {
  <div class="box-shadow fade-in text-start" id="notifications-summary">
    <app-notifications class="disp-block"></app-notifications>
  </div>
  }
  <div class="hidden-desktop pos-rel">
    <div class="pos-abs">
      @if (isloggedIn) {
      <a class="mobile-balance pos-abs pointer" [routerLink]="['/user-profile']" (click)="onAction('user-profile')">
        <span class="balance txt-sm bold">
          <img src="/assets/images/coin.svg" alt="coin" class="va-middle" width="20" height="21" />
          <span>{{ auth.credits | number }}</span>
        </span>
      </a>
      <!-- <button type="button" mat-raised-button class="btn btn-d no-marg-img btn-img notifications pos-rel"
        (click)="showNotifications = !showNotifications" [ngClass]="{ active: showNotifications }">
        <img src="/assets/images/notification.svg" alt="notification" />
        @if (notificationsService.unred) {
        <span class="unred pos-abs">{{notificationsService.unred > 9 ? '+9' : notificationsService.unred}}</span>
        }
      </button> -->
      }
      <button mat-raised-button aria-label="mobile menu" class="mobile-menu btn btn-c btn-img no-marg-img"
        (click)="showMenu = true">
        <img src="/assets/images/menu.svg" alt="menu" class="va-middle" />
        @if (notificationsService.unred) {
        <span class="unred pos-abs">{{notificationsService.unred > 9 ? '+9' : notificationsService.unred}}</span>
        }
      </button>
      <app-header-menu (on-close)="showMenu = false" [ngClass]="{ open: showMenu }"></app-header-menu>
    </div>
  </div>
</header>