<div class="wrap h-100 pos-rel">
  <div class="h-100 disp-flex row inner-wrap">
    <hexa-viewer #hexaViewer class="w-100" embed-loader="true" autorotate="true" auto-adjust="true"
      [ngClass]="{ 'disp-none': !!glbURL }"></hexa-viewer>
    <div class="disp-flex h-100 col prompt-wrap w-100">
      <div class="ttl-xxs space-bottom-md">
        Retexture model
        @if (dialogRef) {
        <button type="button" mat-raised-button class="btn btn-ghost close pos-abs" (click)="close()">
          <img class="btn-icon" src="/assets/images/close.svg" alt="close" />
        </button>
        }
      </div>
      <div class="disp-flex space-bottom-xs">
        <span class="txt-xxs bold">Texture input</span>
        @if (imageToLoad) {
        <a class="txt-xxs bold link underline align-end-flex" href="javascript:void(0)"
          (click)="imageToLoad = null">Clear</a>
        }
      </div>
      <div class="buttons disp-flex space-bottom-sm">
        <div class="button-wrap">
          <button (click)="inputType = GENERATE_TYPE.TEXT_TO_3D"
            [ngClass]="{ 'btn-c': inputType === GENERATE_TYPE.TEXT_TO_3D, 'btn-ghost': inputType !== GENERATE_TYPE.TEXT_TO_3D }"
            type="button" mat-raised-button class="btn">
            Text
          </button>
        </div>
        <div class="button-wrap">
          <button (click)="inputType = GENERATE_TYPE.IMAGE_TO_3D"
            [ngClass]="{ 'btn-c': inputType === GENERATE_TYPE.IMAGE_TO_3D, 'btn-ghost': inputType !== GENERATE_TYPE.IMAGE_TO_3D}"
            type="button" mat-raised-button class="btn sp">
            Image
          </button>
        </div>
      </div>
      @if (inputType === GENERATE_TYPE.TEXT_TO_3D) {
      <!-- <div class="disp-flex ip-wrap">
        <span class="txt-xxs bold">Re-texture prompt</span>
        <a
          href="javascript:void(0)"
          class="align-end-flex link txt-xxs bold"
          (click)="examplePrompt()"
          >example prompt</a
        >
      </div> -->
      <mat-form-field class="w-100 ta-wrap flex-1">
        <textarea id="re-texture-textarea" rows="6" [(ngModel)]="text" placeholder="Insert re-texture prompt here..."
          matInput></textarea>
      </mat-form-field>
      }
      <!-- <div>
        <app-or-separator>or</app-or-separator>
      </div> -->
      @if (inputType === GENERATE_TYPE.IMAGE_TO_3D) {
      <!-- <div class="space-bottom-sm">
        <span class="txt-xxs bold">Upload reference image</span>
      </div> -->
      <div class="pos-rel viewer-wrap space-bottom-lg img-wrap" [ngClass]="{ 'has-file': imageToLoad }">
        @if (!imageToLoad && loadingImageFiles) {
        <mat-progress-bar class="pos-abs" mode="indeterminate" color="accent"></mat-progress-bar>
        }
        <input placeholder="drop image here" type="file" class="poiner" accept="image/png, image/jpeg, image/webp"
          miltiple="false" (change)="onImageFilesChange($event)" />
        <div class="dummy disp-flex pos-abs col text-center">
          <img src="/assets/images/picture.svg" alt="image" />
          <div>
            <div class="desc1 txt-xs capitalize-fl space-bottom-sm">
              upload image
            </div>
            <div class="desc2 txt-xxs">
              png, jpeg, webp
            </div>
          </div>
        </div>
        <img [src]="imageToLoad" alt="reference image" class="reference-image">
      </div>
      }
      @if (action) {
      <div class="advanced-button w-100 space-bottom-md">
        <button class="w-100 btn btn-a ab-l" mat-raised-button [disabled]="isDisabled()" (click)="texture()">
          <img src="/assets/images/ai-texture.svg" alt="ai-texture" class="va-text-top"
            [ngClass]="{ 'filter-disabled': isDisabled() }" />
          Re-texture
        </button>
        <app-advanced-options [action]="action.id" [options]="advancedOptions" [disabled]="isDisabled()"
          (options-change)="onOptionsChange($event)"></app-advanced-options>
      </div>
      @if (showLogin) {
      <div class="login-request">
        @if (oneTapFailure) {
        Please <a class="txt-xxs link" [routerLink]="['/login']">login</a> first.
        }
        @else {
        <div class="warn-color txt-xxs">Please login first.</div>
        }
      </div>
      }
      <app-cost-est [cost]="action.credits" [est]="action.estimated_duration"></app-cost-est>
      }
    </div>
  </div>
</div>