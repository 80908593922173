import { Component, EventEmitter, Injector, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBar } from '@angular/material/progress-bar';
import { IAdvancedOptions, IReconstruct, IReconstructionAction, IReconstructJobUI, RECONSTRUCTION_ACTION } from '../generate';
import { GenerateService } from '../generate.service';
import { PixelsService } from '../../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { CommonModule } from '@angular/common';
import { ImageCardComponent } from '../../shared/image-card/image-card.component';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { UtilsService } from '../../shared/utils.service';
import { ScreenNotificationType } from '../../shared/enums';
import { ProgressHelper } from '../../shared/progress-helper';
import { AdvancedOptionsComponent } from '../advanced-options/advanced-options.component';
import { IMAGE_SAMPLES } from '../../shared/constants';

@Component({
  selector: 'app-generate-results',
  imports: [
    MatIconModule,
    MatButtonModule,
    MatProgressBar,
    CommonModule,
    ImageCardComponent,
    CostEstComponent,
    AdvancedOptionsComponent
  ],
  templateUrl: './generate-results.component.html',
  styleUrl: './generate-results.component.scss'
})
export class GenerateResultsComponent extends ProgressHelper
  implements OnChanges, OnDestroy {
  @Input() images: Array<string>;
  @Input('waiting-mode') waitingMode: boolean;
  @Input('job') item: IReconstructJobUI;
  @Input('is-private') isPrivate: boolean;
  @Input('allow-3d') allow3D: boolean;
  @Output('on-completed') onJobCompleted: EventEmitter<IReconstructJobUI>;
  public loading: boolean;
  public advancedOptions: IAdvancedOptions;
  public actionDetails: IReconstructionAction;
  public hasSimilar: boolean;
  constructor(
    public generateService: GenerateService,
    private pixels: PixelsService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private injector: Injector
  ) {
    super(injector);
    this.advancedOptions = {};
    this.onJobCompleted = new EventEmitter<IReconstructJobUI>();
    this.hasSimilar = !GenerateService.NO_SIMILAR;
    this.init();
  }

  ngOnChanges() {
    this.job = this.item;
  }

  async generate(src: string) {
    if (!this.generateService.checkCreditsAndPrompt((await this.generateService.getAction(RECONSTRUCTION_ACTION.RECONSTRUCTION)).credits, true))
      return;
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_from_preview',
    });
    const payload = {
      action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
      text: this.generateService.text,
      images: [src]
    } as IReconstruct;
    const res = await this.generateService.imageTo3D(payload, this.advancedOptions);
    this.utils.notifyUser({
      type: ScreenNotificationType.Neutral,
      text: 'generating . . .',
    });
    this.broadcaster.broadcast('onPublish', res);
  }

  async retry() {
    if (!this.generateService.checkCreditsAndPrompt((await this.generateService.getAction(RECONSTRUCTION_ACTION.GENERATE_IMAGE)).credits, true))
      return;
    this.generateService.generateImagesFromText({ text: this.generateService.text, public: !this.isPrivate, samples: IMAGE_SAMPLES });
  }

  async select(index: number) {
    if (this.waitingMode) return;
    this.generateService.createdImagesIndex = index;
    this.generateService.similarItems =
      await this.generateService.getSimilarProducts(
        this.generateService.createdImages[
        this.generateService.createdImagesIndex
        ]
      );
  }

  private async init() {
    this.actionDetails = (await this.generateService.getAction(RECONSTRUCTION_ACTION.RECONSTRUCTION));
  }

  onOptionsChange(options: IAdvancedOptions) {
    this.advancedOptions = options;
  }

  override onCompleted() {
    this.onJobCompleted.next(this.job);
  }

  ngOnDestroy() {
    this.destroyProgress();
  }
}
