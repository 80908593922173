<div class="wrap text-center align-items-center justify-content-center">
  <div class="inner vertical-align-desktiop-only">
    @if (allow3D) {
    <div class="text-start ttl-xxs">
      Select the most accurate image to your description
    </div>
    @if (hasSimilar) {
    <p class="text-start txt-xxs">
      Or choose from our high quality ready-to-use similar items
    </p>
    }
    }
    <div class="pos-rel progress-bar-wrap">
      @if (loading) {
      <mat-progress-bar class="pos-abs" mode="indeterminate" color="accent">
      </mat-progress-bar>
      } @else if (waitingMode && percentage) {
      <mat-progress-bar class="pos-abs" mode="determinate" [value]="percentage" color="accent">
      </mat-progress-bar>
      }
    </div>
    <div>
      <div class="images align-items-center">
        @for (img of images; track $index) {
        <app-image-card [ngClass]="{
            'border-gradient': generateService.createdImagesIndex === $index && !waitingMode,
            'waiting': waitingMode
          }" class="pointer disp-block" (click)="select($index)" [src]="img"
          [enable-view]="!waitingMode"></app-image-card>
        }
      </div>
      @if (allow3D) {
      <div class="disp-flex actions space-top">
        <button type="button" mat-raised-button class="btn btn-c large" (click)="retry()" [disabled]="waitingMode">
          <img class="btn-icon" src="/assets/images/retry.svg" alt="retry" />
          Retry
        </button>
        <div class="align-end-flex flex-1">
          @if (actionDetails) {
          <div class="advanced-button">
            <button type="button" mat-raised-button class="btn btn-a large w-100"
              (click)="generate(images[generateService.createdImagesIndex])" [disabled]="waitingMode">
              <img class="btn-icon" src="/assets/images/cube.svg" alt="cube"
                [ngClass]="{'filter-disabled': waitingMode}" />
              Generate 3D model
            </button>
            <app-advanced-options [action]="actionDetails.id" [options]="advancedOptions" [disabled]="waitingMode"
              (options-change)="onOptionsChange($event)"></app-advanced-options>
          </div>
          <app-cost-est [cost]="actionDetails.credits" [est]="actionDetails.estimated_duration"
            class="disp-block space-top"></app-cost-est>
          }
        </div>
      </div>
      }
    </div>
  </div>
</div>