import { Injectable } from '@angular/core';
import { GENERATE_TYPE } from './generate';

@Injectable({
  providedIn: 'root'
})
export class Generate2dHelperService {
  public type: GENERATE_TYPE;
  constructor() { }
}
