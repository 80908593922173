import { Component, Injector, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import {
  IReconstructJobUI,
  RECONSTRUCT_JOB_STATUS,
} from '../generate';
import { GenerateService } from '../generate.service';
import { UtilsService } from '../../shared/utils.service';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import {
  IPlaygroundNotification,
  PlaygroundNotificationType,
} from '../../shared/enums';
import { ProgressHelper } from '../../shared/progress-helper';

@Component({
  selector: 'app-pre-creation-preview',
  imports: [],
  templateUrl: './pre-creation-preview.component.html',
  styleUrl: './pre-creation-preview.component.scss'
})
export class PreCreationPreviewComponent extends ProgressHelper
  implements OnInit, OnDestroy, OnChanges {
  @Input() item: IReconstructJobUI;
  @Input() counter: number;
  public RECONSTRUCT_JOB_STATUS = RECONSTRUCT_JOB_STATUS;
  public InProgress = RECONSTRUCT_JOB_STATUS['In Progress'];
  // private action: IReconstructionAction;
  private _subs: Array<Subscription>;
  // private _destroyed: boolean;
  // public percentage: number;
  constructor(
    private generateService: GenerateService,
    private utils: UtilsService,
    private broadcaster: BroadcasterService,
    private injector: Injector
  ) {
    super(injector);
    this._subs = [];
  }

  async ngOnInit() {
    // await this.setAction();
    this.setJob();
    this._subs.push(
      this.broadcaster.on('onAnnouncement').subscribe((data: any) => {
        this.onAnnouncement(data);
      })
    );
    // this.calcPercentage();
  }

  ngOnChanges() {
    // this.calcPercentage();
    this.setJob();
    this.refreshProgress();
  }

  setJob() {
    this.job = this.item;
  }

  // async setAction() {
  //   this.action = await this.generateService.getAction(this.item.action_id);
  // }

  async onAnnouncement(data: IPlaygroundNotification) {
    if (
      data.notifications_types?.find(
        (t) =>
          t.id === PlaygroundNotificationType.JOB_STATUS_CHANGE ||
          t.id === PlaygroundNotificationType.THREE_D_RECONSTRUCTION_FINISHED
      )
    )
      if (data.job_id === this.item?.id && this.item.status !== data.status) {
        this.item = await this.generateService.updateJob(this.item);
        this.setJob();
        // await this.setAction();
        // this.calcPercentage();
      }
  }

  // async calcPercentage() {
  //   await this.utils.setTimeout();
  //   if (this._destroyed) return;
  //   if (!this.action)
  //     await this.setAction();
  //   if (
  //     this.action &&
  //     this.item.status === RECONSTRUCT_JOB_STATUS['In Progress']
  //   ) {
  //     if (this.action.id !== this.item.action_id)
  //       await this.setAction();
  //     setTimeout(this.calcPercentage.bind(this));
  //     // console.log('app-pre-creation-preview', this.action);
  //     this.percentage = this.utils.calcActionPercentage(this.item, this.action);
  //   }
  // }

  ngOnDestroy() {
    // this._destroyed = true;
    this.destroyProgress();
    this._subs.forEach((s) => s.unsubscribe());
  }
}
