import {
  CUSTOM_ELEMENTS_SCHEMA,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  CREATIONS_LIST_TYPE,
  GENERATE_TYPE,
  IAdvancedOptions,
  IReconstructionAction,
  IReconstructJobUI,
  IReconstructTexture,
  RECONSTRUCTION_ACTION,
  SUBSCRIPTION_REASON,
  TEXTURE_GENERATE_TYPE,
} from '../generate';
import { GenerateService } from '../generate.service';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CreationsComponent } from '../creations/creations.component';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { environment } from '../../../environments/environment';
import { ViewerService } from '../../shared/viewer.service';
import { UtilsService } from '../../shared/utils.service';
import { ExportFileType, IExpotedModel } from 'hexa-viewer-communicator';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { ScreenNotificationType } from '../../shared/enums';
import { PRIVATE_DESC, themeColor } from '../../shared/constants';
import { EnumsService } from '../../shared/enums.service';
import { THREE_LATEST_VERSION } from 'asset-adjustments';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AdvancedOptionsComponent } from '../advanced-options/advanced-options.component';
import { AuthService } from '../../auth/auth.service';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog } from '@angular/material/dialog';
import { PricingDialogComponent } from '../../pricing/pricing-dialog/pricing-dialog.component';
import { Subscription } from 'rxjs';
import { BroadcasterService } from 'ng-broadcaster';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-ai-texture-prompt',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CostEstComponent,
    CommonModule,
    MatButtonModule,
    CreationsComponent,
    MatIconModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    AdvancedOptionsComponent,
    MatTooltipModule,
    RouterLink
  ],
  templateUrl: './ai-texture-prompt.component.html',
  styleUrl: './ai-texture-prompt.component.scss'
})
export class AiTexturePromptComponent implements OnInit, OnDestroy {
  // private _state: TEXTURE_GENERATE_TYPE;
  private _examplePromptIndex: number;
  private _onSubscriptionUpdated: Subscription;
  private _isPrivate: boolean;
  public EXISTING = TEXTURE_GENERATE_TYPE.EXISTING;
  public FOREIGN = TEXTURE_GENERATE_TYPE.FOREIGN;
  public GENERATE_TYPE = GENERATE_TYPE;
  // public cost: number;
  public action: IReconstructionAction;
  public viewerServer: string;
  public viewerService: ViewerService;
  public expordedModel: IExpotedModel;
  public hasFile: boolean;
  public loadingFiles: boolean;
  public loadingImageFiles: boolean;
  public CREATIONS_LIST_TYPE = CREATIONS_LIST_TYPE;
  public themeColor = themeColor;
  public imageToLoad: string;
  public uploading: boolean;
  public advancedOptions: IAdvancedOptions;
  public ttPrivate: string;
  public showLogin: boolean;
  public oneTapFailure: boolean;
  THREE_LATEST_VERSION = THREE_LATEST_VERSION;
  @ViewChild('hexaViewer') hexaViewer: ElementRef;
  constructor(
    public generateService: GenerateService,
    private utils: UtilsService,
    private resumableUploadService: ResumableUploadService,
    private enums: EnumsService,
    public auth: AuthService,
    private dialog: MatDialog,
    private broadcaster: BroadcasterService
  ) {
    this.state = TEXTURE_GENERATE_TYPE.FOREIGN;
    this.inputType = GENERATE_TYPE.TEXT_TO_3D;
    this._examplePromptIndex = 0;
    this.viewerService = new ViewerService(this.utils);
    this.viewerServer = environment.viewerServer;
    this.advancedOptions = {};
    this.utils.preloadImage('/assets/images/cube.svg');
    this.ttPrivate = PRIVATE_DESC;
  }

  get inputType(): GENERATE_TYPE {
    return this.generateService.state;
  }

  set inputType(value: GENERATE_TYPE) {
    this.generateService.state = value;
  }

  ngOnInit() {
    this.init();
    const params = this.utils.getAngularUrlParams();
    if (params?.duplicate) this.duplicate(parseInt(params.duplicate));
  }

  async setAction() {
    this.action = (
      await this.generateService.getAction(this.advancedOptions.pbr ? RECONSTRUCTION_ACTION.RE_TEXTURE_PBR : RECONSTRUCTION_ACTION.RE_TEXTURE)
    );
  }

  async duplicate(id: number) {
    if (id && !isNaN(id)) {
      const job = (await this.generateService.getJobById(id)).data
        .reconstruction_jobs as IReconstructJobUI;
      switch (job.action_id) {
        case RECONSTRUCTION_ACTION.RE_TEXTURE: {
          this.state = this.EXISTING;
          this.generateService.text =
            job.reconstruction_jobs_inputs[0].text_input;
          if (!this.generateService.text)
            this.imageToLoad = job.reconstruction_jobs_inputs[0].image_url;
          if (job.viewer_url) this.onSelect(job);
          break;
        }
      }
    }
  }

  get state() {
    return this.generateService.textureState;
  }

  set state(value: TEXTURE_GENERATE_TYPE) {
    this.generateService.textureState = value;
    if (this.state === this.FOREIGN && this.hasFile) {
      this.hasFile = false;
      this.viewerService.vc.onModelLoaded().then(async () => {
        await this.utils.setTimeout();
        await this.readyForFile();
        this.hasFile = false;
        await this.viewerService.vc.onModelLoaded();
        this.hasFile = true;
        this.loadingFiles = false;
      });
    }
  }

  private async init() {
    await this.setAction();
    this.readyForFile();
  }

  async readyForFile() {
    await this.viewerService.injectScript();
    if (!this.hexaViewer?.nativeElement)
      this.hexaViewer = new ElementRef(
        document.getElementById('ai-texture-prompt-viewer')
      );
    this.hexaViewer.nativeElement.setAttribute('server', this.viewerServer);
    this.hexaViewer.nativeElement.setAttribute('tv', THREE_LATEST_VERSION);
    this.hexaViewer.nativeElement.setAttribute('theme-color', this.themeColor);
    this.viewerService.defineVC(this.hexaViewer.nativeElement);
    await this.viewerService.vc.onModelLoaded();
    this.hasFile = true;
    this.loadingFiles = false;
  }

  async handleModel() {
    this.expordedModel = await this.viewerService.vc.expotModel({
      compressPNG: false,
      downloadFile: false,
      type: ExportFileType.GLB,
    });
  }

  isDisable() {
    if (this.state === this.EXISTING) {
      if (this.inputType === GENERATE_TYPE.IMAGE_TO_3D)
        return !this.generateService.creation?.glb_url || !this.imageToLoad;
      else
        return !this.generateService.creation?.glb_url || !this.generateService.text;
    } else {
      if (this.inputType === GENERATE_TYPE.IMAGE_TO_3D)
        return !this.imageToLoad || !this.hasFile;
      else
        return !this.generateService.text || !this.hasFile;
    }
  }

  onSelect(item: IReconstructJobUI) {
    this.generateService.creation = item;
    this.generateService.counter++;
  }

  async texture() {
    if (!await this.auth.isloggedIn()) {
      this.showLogin = true;
      try {
        await this.auth.oneTapAuth();
        this.showLogin = false;
        this.texture();
      } catch (e) {
        this.oneTapFailure = true;
      }
    }
    else {
      if (!this.generateService.checkCreditsAndPrompt(this.action.credits, true))
        return;
      let glbUrl;
      this.uploading = true;
      switch (this.state) {
        case TEXTURE_GENERATE_TYPE.EXISTING: {
          glbUrl = this.generateService.creation.glb_url;
          break;
        }
        case TEXTURE_GENERATE_TYPE.FOREIGN: {
          this.utils.notifyUser({
            type: ScreenNotificationType.Info,
            text: 'Exporting model . . .',
          });
          await this.handleModel();
          const blob = new Blob([this.expordedModel.buffer], {
            type: 'application/octet-stream',
          }) as any;
          blob.lastModifiedDate = new Date();
          blob.name = 'scene.glb';
          glbUrl = await this.resumableUploadService.file(blob);
          this.utils.notifyUser({
            type: ScreenNotificationType.Info,
            text: 'Uploading model . . .',
          });
          break;
        }
      }
      const payload = { modelURL: glbUrl } as IReconstructTexture;
      if (this.inputType === GENERATE_TYPE.IMAGE_TO_3D)
        payload.images = [this.imageToLoad];
      else
        payload.text = this.generateService.text;
      const res = (await this.generateService.generateTexture(payload, this.advancedOptions)) as IReconstructJobUI;
      res._delayEnter = 1;
      // this.generateService.latestUpdated = res;
      this.utils.notifyUser({
        type: ScreenNotificationType.Info,
        text: 'Texturing model . . .',
      });
      this.uploading = false;
    }
  }

  examplePrompt() {
    const prompts = this.enums.getTextureExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.generateService.text = p;
  }

  async onFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.loadingFiles = true;
    this.viewerService.vc.displayFiles(event.srcElement.files);
  }

  async onImageFilesChange(event: any) {
    if (!event.srcElement.files.length) return;
    this.loadingImageFiles = true;
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp')
        continue;
      this.imageToLoad = await this.resumableUploadService.file(file);
      break;
    }
    event.srcElement.value = '';
    this.loadingImageFiles = false;
  }

  onOptionsChange(options: IAdvancedOptions) {
    this.advancedOptions = options;
    this.setAction();
  }

  showPricing(action_id: SUBSCRIPTION_REASON) {
    this.dialog.open(PricingDialogComponent, {
      data: action_id
    });
  }

  get isPrivate() {
    return this._isPrivate;
  }

  set isPrivate(value: boolean) {
    const setIsPrivate = (v: boolean) => {
      this._isPrivate = v;
      this.advancedOptions.private = this.isPrivate;
    };
    if (value && !this.generateService.canRequestPrivate()) {
      this.showPricing(SUBSCRIPTION_REASON.PRIVATE);
      this._onSubscriptionUpdated = this.broadcaster.on('subscriptionUpdated').subscribe(() => {
        if (this.auth.subscription)
          setIsPrivate(true);
        this._onSubscriptionUpdated.unsubscribe();
      });
    }
    else
      setIsPrivate(value);
  }

  ngOnDestroy() {
    this.viewerService.destroy();
  }
}
