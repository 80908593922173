import { Injectable, Injector } from '@angular/core';
import { InfiniteScroll } from '../shared/infinite-scroll';
import { UtilsService } from '../shared/utils.service';
import { KeyValuePair } from '../shared/enums';
import { IReconstructImagesFilterOptions } from './creations';
import { imageCardDelay } from '../shared/constants';
import { IReconstructJobUI } from './generate';

@Injectable({
  providedIn: 'root'
})
export class GeneratedImagesService extends InfiniteScroll {
  static DEFAULT_FILTER = {
    limit: 18,
    offset: 0,
    is_archived: false
    // is_desc: true,
    // order_by: 'session_id',
  } as IReconstructImagesFilterOptions;
  public fields: Array<KeyValuePair>;
  public searchFields: Array<KeyValuePair>;
  constructor(
    private utils: UtilsService,
    private injector: Injector
  ) {
    super(
      {
        chunkSize: GeneratedImagesService.DEFAULT_FILTER.limit,
        graphFunction: 'allReconstructionJobs',
        defaults: {
          is_desc: GeneratedImagesService.DEFAULT_FILTER.is_desc,
          order_by: GeneratedImagesService.DEFAULT_FILTER.order_by
        } as IReconstructImagesFilterOptions,
      },
      injector
    );
    this.initFilter();
  }

  override afterItemsChanged(forceRefresh: boolean) {
    super.afterItemsChanged(forceRefresh);
    let index = 0;
    this.items.forEach((item: IReconstructJobUI) => {
      if (typeof item._delayEnter !== 'number') {
        item._delayEnter = item._delayEnter || ++index * imageCardDelay;
      }
    });
  }

  override initFilter() {
    super.initFilter();
    this.ignoreChanges = false;
    this.filter.removeControl('order_by');
    this.filter.removeControl('is_desc');
    this.ignoreChanges = true;
    this.lastQuery = this.getSearchQuery();
  }
}
