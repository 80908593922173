import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  GENERATE_TYPE,
  IAdvancedOptions,
  IReconstruct,
  IReconstructionAction,
  RECONSTRUCTION_ACTION,
  SUBSCRIPTION_REASON,
} from '../generate';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormsModule } from '@angular/forms';
import { UtilsService } from '../../shared/utils.service';
import {
  ScreenNotificationType,
  KeyValuePair,
  RECONSTRUCTION_OPTIONS,
} from '../../shared/enums';
import { ResumableUploadService } from '../../shared/resumable-upload.service';
import { GenerateService } from '../generate.service';
import { PixelsService } from '../../shared/pixels.service';
import { BroadcasterService } from 'ng-broadcaster';
import { MatIconModule } from '@angular/material/icon';
import { EnumsService } from '../../shared/enums.service';
import { CostEstComponent } from '../../shared/cost-est/cost-est.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AdvancedOptionsComponent } from '../advanced-options/advanced-options.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AuthService } from '../../auth/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { PricingDialogComponent } from '../../pricing/pricing-dialog/pricing-dialog.component';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { IMAGE_SAMPLES, PRIVATE_DESC } from '../../shared/constants';

@Component({
  selector: 'app-prompt',
  imports: [
    MatButtonModule,
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatIconModule,
    CostEstComponent,
    MatProgressBarModule,
    AdvancedOptionsComponent,
    MatTooltipModule,
    RouterLink
  ],
  templateUrl: './prompt.component.html',
  styleUrl: './prompt.component.scss'
})
export class PromptComponent implements OnDestroy, OnInit {
  @Input('force-state') forceState: GENERATE_TYPE;
  @Output('on-private') onPrivate: EventEmitter<boolean>;
  private _examplePromptIndex: number;
  private _afterFilesChange: Array<Function>;
  private _isPrivate: boolean;
  private _onSubscriptionUpdated: Subscription;
  public TEXT_TO_3D = GENERATE_TYPE.TEXT_TO_3D;
  public IMAGE_TO_3D = GENERATE_TYPE.IMAGE_TO_3D;
  public TEXT_TO_IMAGE_TO_3D = RECONSTRUCTION_OPTIONS.TEXT_TO_IMAGE_TO_3D;
  public imageToUpload: Array<string>;
  public uploadingImage: boolean;
  public allTextOptions: Array<KeyValuePair>;
  public current2dType: RECONSTRUCTION_OPTIONS;
  public actionDetails: IReconstructionAction;
  public action: RECONSTRUCTION_ACTION;
  public uploadImagesID = 'upload-images';
  public advancedOptions: IAdvancedOptions;
  public ttPrivate: string;
  public showLogin: boolean;
  public oneTapFailure: boolean;
  constructor(
    private utils: UtilsService,
    private uploadService: ResumableUploadService,
    public generateService: GenerateService,
    private pixels: PixelsService,
    private broadcaster: BroadcasterService,
    private enums: EnumsService,
    public auth: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this._isPrivate = false;
    this.imageToUpload = [];
    this._afterFilesChange = [];
    // this.state = GENERATE_TYPE.TEXT_TO_3D;
    this._examplePromptIndex = 0;
    this.allTextOptions = this.enums.getTextOptions();
    this.current2dType = this.allTextOptions[0].key;
    this.advancedOptions = {};
    this.ttPrivate = PRIVATE_DESC;
    this.onPrivate = new EventEmitter<boolean>();
    this.init();
  }

  get state() {
    return this.generateService.state;
  }

  set state(value: GENERATE_TYPE) {
    this.generateService.state = value;
    switch (this.generateService.state) {
      case GENERATE_TYPE.IMAGE_TO_3D: {
        this.router.navigate(['/free-online-ai-3d-image-to-model-generator']);
        break;
      }
      case GENERATE_TYPE.TEXT_TO_3D: {
        this.router.navigate(['/free-online-ai-text-to-3d-model-generator']);
        break;
      }
    }
  }

  get isPrivate() {
    return this._isPrivate;
  }

  set isPrivate(value: boolean) {
    const setIsPrivate = (v: boolean) => {
      this._isPrivate = v;
      this.advancedOptions.private = this.isPrivate;
      this.onPrivate.next(this.isPrivate);
    };
    if (value && !this.generateService.canRequestPrivate()) {
      this.showPricing(SUBSCRIPTION_REASON.PRIVATE);
      this._onSubscriptionUpdated = this.broadcaster.on('subscriptionUpdated').subscribe(() => {
        if (this.auth.subscription)
          setIsPrivate(true);
        this._onSubscriptionUpdated.unsubscribe();
      });
    }
    else
      setIsPrivate(value);
  }

  showPricing(action_id: SUBSCRIPTION_REASON) {
    this.dialog.open(PricingDialogComponent, {
      data: action_id
    });
  }

  private async init() {
    this.setActionDetails();
  }

  ngOnInit() {
    let params = this.utils.getAngularUrlParams();
    if (params?.duplicate)
      this.duplicate(parseInt(params.duplicate), parseInt(params.img));
    params = this.activatedRoute.snapshot.params as any;
    if (params?.cid)
      this.duplicate(parseInt(params.cid), parseInt(params.iid));
    if (this.forceState)
      this.generateService.state = this.forceState;
  }

  async setActionDetails() {
    this.action = this.state === this.TEXT_TO_3D ? RECONSTRUCTION_ACTION.GENERATE_IMAGE : RECONSTRUCTION_ACTION.RECONSTRUCTION;
    this.actionDetails = (
      await this.generateService.getAction(this.action)
    );
  }

  async setState(state: GENERATE_TYPE) {
    this.state = state;
    await this.setActionDetails();
  }

  async duplicate(id: number, imgId: number) {
    if (this.auth.isloggedIn()) {
      if (id && !isNaN(id)) {
        const job = (await this.generateService.getJobById(id)).data.reconstruction_jobs;
        if (imgId && !isNaN(imgId)) {
          this.imageToUpload = [job.reconstruction_images.find(ri => ri.id === imgId).url];
          this.getSimilarFromImage(this.imageToUpload[0]);
          this.generateService.text =
          await this.generateService.getImageClassification(
            this.imageToUpload[0],
            true
          );
        }
        else {
          switch (job.source_action_id || job.action_id) {
            case RECONSTRUCTION_ACTION.RECONSTRUCTION_FROM_TEXT: {
              this.state = this.TEXT_TO_3D;
              this.generateService.text = job.text;
              break;
            }
            case RECONSTRUCTION_ACTION.RECONSTRUCTION: {
              this.state = this.IMAGE_TO_3D;
              this.generateService.text = job.reconstruction_jobs_inputs[0].text_input;
              this.imageToUpload = job.reconstruction_jobs_inputs.map(r => r.image_url);
              if (!this.imageToUpload.length)
                this.imageToUpload[0] = job.preview;
              break;
            }
          }
        }
        this.setActionDetails();
      }
    }
  }

  async generateFromText() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_from_text',
    });
    if (!await this.auth.isloggedIn()) {
      this.showLogin = true;
      try {
        await this.auth.oneTapAuth();
        this.showLogin = false;
        this.generateFromText();
      } catch (e) {
        this.oneTapFailure = true;
      }
    }
    else {
      if (!this.generateService.checkCreditsAndPrompt(this.actionDetails.credits, true))
        return;
      const payload = {
        action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
        text: this.generateService.text,
        public: !this.isPrivate
      } as IReconstruct;
      this.getSimilarFromText();
      this.generateService.generatingImagesJob = null;
      const job = await this.generateService.imageTo3D(payload, this.advancedOptions);
      this.utils.notifyUser({
        type: ScreenNotificationType.Neutral,
        text: 'generating . . .',
      });
      this.generateService.text = null;
      this.broadcaster.broadcast('onGenerating', job);
    }
  }

  async generate() {
    this.pixels.sendPixel({
      event: 'click',
      click_type: 'generate_3d',
      sub_click_type: 'generate_from_image',
    });
    if (!await this.auth.isloggedIn()) {
      this.showLogin = true;
      try {
        await this.auth.oneTapAuth();
        this.showLogin = false;
        this.generate();
      } catch (e) {
        this.oneTapFailure = true;
      }
    }
    else {
      if (!this.generateService.checkCreditsAndPrompt(this.actionDetails.credits, true))
        return;
      switch (this.state) {
        case GENERATE_TYPE.TEXT_TO_3D: {
          await this.generateService.generateImagesFromText({
            text: this.generateService.text,
            public: !this.isPrivate,
            samples: IMAGE_SAMPLES
          });
          break;
        }
        case GENERATE_TYPE.IMAGE_TO_3D: {
          const payload = {
            action_id: RECONSTRUCTION_ACTION.RECONSTRUCTION,
            text: this.generateService.text,
            images: this.imageToUpload,
            public: !this.isPrivate
          } as IReconstruct;
          if (this.imageToUpload[0]) this.getSimilarFromImage(this.imageToUpload[0]);
          else this.getSimilarFromText();
          const job = await this.generateService.imageTo3D(payload, this.advancedOptions);
          this.imageToUpload = [];
          this.utils.notifyUser({
            type: ScreenNotificationType.Neutral,
            text: 'generating . . .',
          });
          this.generateService.text = null;
          this.broadcaster.broadcast('onGenerating', job);
        }
      }
    }
  }

  async getSimilarFromText() {
    // We'll stop doing that as it costs credits
    return;
    const res = await this.generateService.reconstructImage(
      {
        text: this.generateService.text,
        public: !this.isPrivate,
        samples: 1
      }
      , () => {
        this.getSimilarFromImage(res.reconstruction_images[0].url);
      });
  }

  async getSimilarFromImage(url: string) {
    this.generateService.similarItems =
      await this.generateService.getSimilarProducts(url);
  }

  async onFilesChange(event: any) {
    if (this.uploadingImage) {
      this._afterFilesChange.push(this.onFilesChange.bind(this), event);
      return;
    }

    this.uploadingImage = true;

    // let base64Failures = {} as { [id: number]: boolean };
    const initialIndex = this.imageToUpload.length;
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp')
        continue;
      try {
        const b64 = await this.utils.getBase64FromFile(file);
        if (b64)
          this.imageToUpload.push(b64);
        // else
        //   base64Failures[i] = true;
      } catch (e) {
        // base64Failures[i] = true;
      }
    }
    for (let i = 0; i < event.srcElement.files.length; i++) {
      const file = event.srcElement.files[i];
      if (!file) break;
      if (file.type !== 'image/jpeg' && file.type !== 'image/png' && file.type !== 'image/webp')
        continue;
      const url = await this.uploadService.file(file);
      // if (!base64Failures[i])
      //   this.imageToUpload[i] = url;
      // else
      //   this.imageToUpload.push(url);
      this.imageToUpload[initialIndex + i] = url;
      if (!this.generateService.text && i === 0)
        this.generateService.text =
          await this.generateService.getImageClassification(
            this.imageToUpload[0],
            true
          );
    }
    event.srcElement.value = '';
    this.uploadingImage = false;

    if (this._afterFilesChange.length)
      this._afterFilesChange.pop()();
  }

  isDisable() {
    if (this.state === this.IMAGE_TO_3D) {
      return (
        !this.imageToUpload[0] ||
        !!this.uploadingImage ||
        !this.generateService.text
      );
    } else {
      return !this.generateService.text;
    }
  }

  generateImages() {
    this.onTextGen(this.allTextOptions[0]);
  }

  onTextGen(selection: KeyValuePair) {
    switch (selection.key) {
      case RECONSTRUCTION_OPTIONS.TEXT_TO_3D: {
        this.generateFromText();
        break;
      }
      case RECONSTRUCTION_OPTIONS.TEXT_TO_IMAGE_TO_3D: {
        this.generate();
        break;
      }
    }
  }

  onTypeChange(type: KeyValuePair) {
    this.current2dType = type.key;
  }

  examplePrompt() {
    const prompts = this.enums.getExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.generateService.text = p;
  }

  async setExampleImage() {
    // this.imageToUpload.push('https://himg-cdn.com/hotlink-ok/20250225/5f69eda56dfc48eaa13ac3196225afab.png');
    this.imageToUpload.push('https://himg-cdn.com/hotlink-ok/20250203/7f13f95adff14291b0f8edf74187a77c.webp');
    if (!this.generateService.text)
      this.generateService.text = 'Chairs & Stools';
    // this.generateService.text =
    //   await this.generateService.getImageClassification(
    //     this.imageToUpload[0],
    //     true
    //   );
  }

  addMoreImages() {
    document.getElementById(this.uploadImagesID).click();
  }

  removeImage(index: number) {
    this.imageToUpload.splice(index, 1);
  }

  getSliderWidth() {
    if (this.imageToUpload.length > 1)
      return 'calc(' + this.imageToUpload.length + '*86px)';
    return '82px';
  }

  onOptionsChange(options: IAdvancedOptions) {
    this.advancedOptions = options;
    // this.advancedOptions.is_private = this.isPrivate;
  }

  ngOnDestroy() {
    delete this.generateService.creation;
    delete this.generateService.createdImages;
    // this.state = GENERATE_TYPE.TEXT_TO_3D;
    this._onSubscriptionUpdated?.unsubscribe();
  }
}
