import { Component } from '@angular/core';
import { EnumsService } from '../../shared/enums.service';
import { GenerateService } from '../generate.service';
import { CommonModule } from '@angular/common';
import { GenerateResultsComponent } from '../generate-results/generate-results.component';
import { SimilarComponent } from '../similar/similar.component';
import { GENERATE_TYPE, IMAGE_LIST_TYPE, IReconstructImage, IReconstructImageSelect, IReconstructionAction, IReconstructJob, IReconstructJobUI, ISimilarItem, RECONSTRUCTION_ACTION } from '../generate';
import { noPaddDialog } from '../../shared/constants';
import { SimilarPreviewComponent } from '../similar-preview/similar-preview.component';
import { MatDialog } from '@angular/material/dialog';
import { GeneratedImagesComponent } from '../generated-images/generated-images.component';
import { RouterLink } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { SeoService } from '../../shared/seo/seo.service';
import { MetaOptions } from '../../shared/seo/seo';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { PromptComponent } from '../prompt/prompt.component';
import { ViewGeneratedImageComponent } from '../view-generated-image/view-generated-image.component';
import { GeneratedImagesService } from '../generated-images.service';
import { UtilsService } from '../../shared/utils.service';

@Component({
  selector: 'app-create-images',
  imports: [PromptComponent, MatDividerModule, RouterLink, GeneratedImagesComponent, CommonModule, GenerateResultsComponent, SimilarComponent, ViewGeneratedImageComponent],
  templateUrl: './create-images.component.html',
  styleUrl: './create-images.component.scss'
})
export class CreateImagesComponent {
  private _examplePromptIndex: number;
  public showSimilar: boolean;
  public isPrivate: boolean;
  public IMAGE_LIST_TYPE = IMAGE_LIST_TYPE;
  public ttPrivate: string;
  public action: IReconstructionAction;
  public GENERATE_TYPE = GENERATE_TYPE;
  constructor(
    public generateService: GenerateService,
    private enums: EnumsService,
    private dialog: MatDialog,
    private seo: SeoService,
    public auth: AuthService,
    public giService: GeneratedImagesService,
    private utils: UtilsService,
  ) {
    this._examplePromptIndex = 0;
    const mOptions = new MetaOptions();
    mOptions.title = `Free Online AI Text-to-Image Generator & Editor | Create Stunning Visuals from Text`;
    mOptions.keywords = 'AI text to image, text to image generator, free online image creator, AI image generator, creative image tool, high resolution images, artistic image generator, text prompt visuals, AI visual art';
    mOptions.description = 'Transform your words into vivid, high-resolution images with our free online AI tool. Enter a text prompt and let our advanced technology generate creative visuals perfect for artistic projects, marketing, and social media.';
    mOptions.canonical = `${environment.domain}free-online-ai-text-to-image-generator`;
    this.seo.setMetaDate(mOptions);
    this.ttPrivate = 'By toggling private mode on you can prevent those images from being shown on this platform by others. The image IP is yours.';
    this.setAction();
  }

  async setAction() {
    this.action = (
      await this.generateService.getAction(RECONSTRUCTION_ACTION.GENERATE_IMAGE)
    );
  }

  examplePrompt() {
    const prompts = this.enums.getExamplePrompts();
    let p = prompts[this._examplePromptIndex++];
    if (!p) {
      this._examplePromptIndex = 0;
      p = prompts[this._examplePromptIndex];
    }
    this.generateService.text = p;
  }

  isEmpty() {
    return !this.generateService.createdImages;
  }

  toggleSimilar() {
    this.showSimilar = !this.showSimilar;
  }

  setSimilar(similar: ISimilarItem) {
    document.body.classList.add(noPaddDialog);
    this.dialog.open(SimilarPreviewComponent, {
      data: similar,
    });
    this.dialog.afterAllClosed.subscribe(() => {
      document.body.classList.remove(noPaddDialog);
    });
  }

  onImageSelect(item: IReconstructImageSelect) {
    this.generateService.reconstructedImage = item.image;
    this.generateService.creation = item.job;
  }

  isDisable() {
    return !!this.generateService.text;
  }

  setPrivate(value: boolean) {
    this.isPrivate = value;
  }

  async onCompleted(job: IReconstructJobUI) {
    const j = this.utils.deepCopyByValue((await this.generateService.getJobById(job.id)).data.reconstruction_jobs);
    this.giService.items.unshift(j);
    this.giService.items = [...this.giService.items];
    this.giService.itemsCount++;
  }
}
