import { Component, Injector, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IReconstructJobUI } from '../generate';
import { ProgressHelper } from '../../shared/progress-helper';
import { BroadcasterService } from 'ng-broadcaster';
import { UtilsService } from '../../shared/utils.service';
import { GenerateService } from '../generate.service';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ScreenNotificationType } from '../../shared/enums';

@Component({
  selector: 'app-view-generated-video',
  imports: [MatProgressBarModule],
  templateUrl: './view-generated-video.component.html',
  styleUrl: './view-generated-video.component.scss'
})
export class ViewGeneratedVideoComponent extends ProgressHelper implements OnChanges {
  @Input() item: IReconstructJobUI;
  public loadingVideo: boolean;
  constructor(
    private generateService: GenerateService,
    private broadcaster: BroadcasterService,
    private utils: UtilsService,
    private injector: Injector
  ) {
    super(injector);
    this.loadingVideo = true;
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['item']) {
      this.job = this.item;
      if (this.item.viewer_url)
        this.loadingVideo = true;
      else
        this.loadingVideo = false;
    }
  }

  override async onCompleted() {
    this.item = this.utils.deepCopyByValue((await this.generateService.getJobById(this.job.id)).data.reconstruction_jobs) as IReconstructJobUI;
  }

  override onFailed() {
    this.utils.notifyUser({
      text: 'Video creation failed',
      type: ScreenNotificationType.Error
    });
  }

  onPlay(evt: any) {
    this.loadingVideo = false;
    const e = this.getVidElement(evt);
    if (e)
      e.controls = false;
  }

  getVidElement(evt: any): HTMLVideoElement {
    return evt.srcElement || evt.target;
  }

  canplay(evt: any) {
    const e = this.getVidElement(evt);
    if (e)
      e.play().catch(() => {
        this.loadingVideo = false;
        e.controls = true;
      });
  }
}
