import { Component, Input } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { BroadcasterService } from 'ng-broadcaster';

@Component({
  selector: 'app-generate-header-link',
  imports: [MatButtonModule, RouterLink, RouterLinkActive],
  templateUrl: './generate-header-link.component.html',
  styleUrl: './generate-header-link.component.scss'
})
export class GenerateHeaderLinkComponent {
  @Input() link: string;
  @Input() img: string;
  @Input() alt: string;
  @Input() text: string;
  @Input() subtext: string;
  constructor(private broadcaster: BroadcasterService) {

  }

  onAction(route: string) {
    this.broadcaster.broadcast('onHeaderAction', route);
  }
}
