import { Routes } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ThemeComponent } from './theme/theme.component';
import { GenerateComponent } from './generate/generate/generate.component';
import { TermsComponent } from './auth/terms/terms.component';
import { PrivacyComponent } from './auth/privacy/privacy.component';
import { HomeComponent } from './home/home.component';
import { CreationsWrapComponent } from './generate/creations-wrap/creations-wrap.component';
import { BuyComponent } from './payments/buy/buy.component';
// import { RegisterComponent } from './auth/register/register.component';
import { RecoverPasswordComponent } from './auth/recover-password/recover-password.component';
import { RecoverPasswordResolve } from './auth/recover-password/recover-password.resolve';
import { SocialComponent } from './auth/social/social.component';
import { SocialResolve } from './auth/social/social.resolve';
import { PricingComponent } from './pricing/pricing/pricing.component';
import { ActionsResolve } from './auth/actions.resolve';
import { AiTextureWrapComponent } from './generate/ai-texture-wrap/ai-texture-wrap.component';
import { UserProfileWrapComponent } from './user-profile-wrap/user-profile-wrap.component';
import { TransactionsResolve } from './auth/user-profile/transactions.resolve';
import { TopupsComponent } from './pricing/topups/topups.component';
import { PreviewWrapComponent } from './shared/preview-wrap/preview-wrap.component';
import { SiteComponent } from './site/site.component';
import { BlankComponent } from './shared/blank/blank.component';
import { CanActivateSuGuard } from './auth/can-activate-auth-guard.service';
import { UpscaleWrapComponent } from './generate/upscale-wrap/upscale-wrap.component';
import { EditImageWrapComponent } from './generate/edit-image-wrap/edit-image-wrap.component';
import { CreateImagesComponent } from './generate/create-images/create-images.component';
// import { CreationsResolve } from './generate/creations/creations.resolve';

export const routes: Routes = [
    { path: '', component: SiteComponent },
    { path: 'home', component: SiteComponent },
    { path: 'about', component: HomeComponent },
    // { path: 'register', component: RegisterComponent },
    { path: 'blank', component: BlankComponent },
    { path: 'login', component: LoginComponent },
    // { path: 'signup/:email/:name/:token', component: LoginComponent },
    // { path: 'reset/password/:email/:token', component: LoginComponent },
    // { path: 'reset/password/:email/:token/:enforce', component: LoginComponent },
    {
        path: 'retailer/user/password/:email',
        // path: 'retailer/user/password',
        component: RecoverPasswordComponent,
        resolve: {
            token: RecoverPasswordResolve
        }
    },
    {
        path: 'auth/sso',
        resolve: {
            profile: SocialResolve
        },
        component: SocialComponent
    },
    { path: 'update/qr/:email/:token', component: LoginComponent },
    { path: 'theme', component: ThemeComponent },
    // {
    //     path: 'generate',
    //     component: GenerateComponent,
    //     resolve: {
    //         actions: ActionsResolve
    //     }
    // },
    {
        path: 'free-online-ai-text-to-3d-model-generator',
        component: GenerateComponent,
        resolve: {
            actions: ActionsResolve
        }
    },
    {
        path: 'free-online-ai-3d-image-to-model-generator',
        component: GenerateComponent,
        resolve: {
            actions: ActionsResolve
        }
    },
    {
        path: 'free-online-ai-3d-image-to-model-generator/:cid',
        component: GenerateComponent,
        resolve: {
            actions: ActionsResolve
        }
    },
    {
        path: 'free-online-ai-3d-image-to-model-generator/:cid/:iid',
        component: GenerateComponent,
        resolve: {
            actions: ActionsResolve
        }
    },
    { path: 'creations', component: CreationsWrapComponent },
    { path: 'creations/:cid', component: CreationsWrapComponent },
    { path: 'creations/:cid/:iid', component: CreationsWrapComponent },
    { path: 'credits', component: BuyComponent },
    { path: 'terms', component: TermsComponent },
    { path: 'privacy', component: PrivacyComponent },
    { path: 'pricing', component: PricingComponent },
    { path: 'topups', component: TopupsComponent },
    { path: 'free-online-ai-texture-generator', component: AiTextureWrapComponent },
    {
        path: 'user-profile',
        component: UserProfileWrapComponent,
        resolve: {
            transactions: TransactionsResolve
        }
    },
    { path: 'preview', component: PreviewWrapComponent },
    // { path: 'render', component: RenderComponent },
    {
        path: 'render',
        loadComponent: () =>
            import('./render/render/render.component').then(m => m.RenderComponent)
    },
    {
        path: 'broadcast',
        canActivate: [CanActivateSuGuard],
        loadComponent: () =>
            import('./shared/broadcast-notifications/broadcast-notifications.component').then(m => m.BroadcastNotificationsComponent)
    },
    { path: 'free-online-image-upscale', component: UpscaleWrapComponent },
    { path: 'free-online-image-upscale/:cid', component: UpscaleWrapComponent },
    { path: 'free-online-image-upscale/:cid/:iid', component: UpscaleWrapComponent },
    { path: 'free-online-ai-photo-editor', component: EditImageWrapComponent },
    { path: 'free-online-ai-photo-editor/:cid', component: EditImageWrapComponent },
    { path: 'free-online-ai-photo-editor/:cid/:iid', component: EditImageWrapComponent },
    { path: 'online-ai-video-generator', component: EditImageWrapComponent },
    { path: 'online-ai-video-generator/:cid', component: EditImageWrapComponent },
    { path: 'online-ai-video-generator/:cid/:iid', component: EditImageWrapComponent },
    { path: 'free-online-ai-text-to-image-generator', component: CreateImagesComponent },
    {
        path: 'free-online-video-storyboard-editor',
        loadComponent: () =>
            import('./video-editor/video-editor-wrap/video-editor-wrap.component').then(m => m.VideoEditorWrapComponent)
    },
];
