<div class="h-100 pos-rel wrap disp-flex">
    @if (item.viewer_url) {
    <video muted loop playsinline [src]="item.viewer_url" autoplay (play)="onPlay($event)"
        (canplay)="canplay($event)"></video>
    }
    @if (item.viewer_url && loadingVideo) {
    <mat-progress-bar mode="indeterminate" class="pos-abs indeterminate" color="accent">
    </mat-progress-bar>
    }
    @else if (job && !item.viewer_url) {
    <div class="w-100 disp-flex col pc-center-center text-center">
        @if (percentage) {
        <h3>Generating video... {{ percentage }}%</h3>
        <div class="desc txt-xxs">
            This may take a some time, you can wait here or generate another video.
            <br />
            We’ll notify you when its ready.
        </div>
        }
        @else {
        <mat-progress-bar mode="indeterminate" color="accent">
        </mat-progress-bar>
        }
    </div>
    }
</div>